import React from 'react';

export const StaattinenVaakagraafi = ({ title, xaxis, yaxis, values }) => {
    return (
        <div>
            <span class="bar-chart-title">{title}</span>
            <svg width="100%" viewBox="0,0,1000,200" style={{ marginTop: 30 }}>
                {/* Color Bars */}
                <g>
                    <rect fill="#800000" x="400" y="30" width={parseInt(values[0] / xaxis[5] * 500)} height="28"></rect>
                    <rect fill="#bbb" x="400" y="60" width={parseInt(values[1] / xaxis[5] * 500)} height="28"></rect>
                </g>
                {/* Data Labels */}
                <g text-anchor="end" font-size="16" font-family="arial">

                    <text fill="white" x={parseInt(400 + (values[0] / xaxis[5] * 500))} y="30" dy="18" dx="-4">{values[0]}</text>
                    <text fill="black" x={parseInt(400 + (values[1] / xaxis[5] * 500))} y="60" dy="18" dx="-4">{values[1]}</text>
                </g>
                {/* X Axis */}
                <g transform="translate(0,30)" fill="none" font-size="16" font-family="arial" text-anchor="middle">
                    <g class="tick" opacity="1" transform="translate(400,0)">
                        <line stroke="currentColor" y2="-6"></line>
                        <text fill="currentColor" y="-9" dy="0em">{xaxis[0]}</text>
                    </g>
                    {/*<g class="tick" opacity="1" transform="translate(450,0)">*/}
                    {/*	<line stroke="currentColor" y2="-6"></line>*/}
                    {/*	<text fill="currentColor" y="-9" dy="0em">10%</text>*/}
                    {/*</g>*/}
                    <g class="tick" opacity="1" transform="translate(500,0)">
                        <line stroke="currentColor" y2="-6"></line>
                        <text fill="currentColor" y="-9" dy="0em">{xaxis[1]}</text>
                    </g>
                    {/*<g class="tick" opacity="1" transform="translate(550,0)">*/}
                    {/*	<line stroke="currentColor" y2="-6"></line>*/}
                    {/*	<text fill="currentColor" y="-9" dy="0em">30%</text>*/}
                    {/*</g>*/}
                    <g class="tick" opacity="1" transform="translate(600,0)">
                        <line stroke="currentColor" y2="-6"></line>
                        <text fill="currentColor" y="-9" dy="0em">{xaxis[2]}</text>
                    </g>
                    {/*<g class="tick" opacity="1" transform="translate(650,0)">*/}
                    {/*	<line stroke="currentColor" y2="-6"></line>*/}
                    {/*	<text fill="currentColor" y="-9" dy="0em">50%</text>*/}
                    {/*</g>*/}
                    <g class="tick" opacity="1" transform="translate(700,0)">
                        <line stroke="currentColor" y2="-6"></line>
                        <text fill="currentColor" y="-9" dy="0em">{xaxis[3]}</text>
                    </g>
                    {/*<g class="tick" opacity="1" transform="translate(750,0)">*/}
                    {/*	<line stroke="currentColor" y2="-6"></line>*/}
                    {/*	<text fill="currentColor" y="-9" dy="0em">70%</text>*/}
                    {/*</g>*/}
                    <g class="tick" opacity="1" transform="translate(800,0)">
                        <line stroke="currentColor" y2="-6"></line>
                        <text fill="currentColor" y="-9" dy="0em">{xaxis[4]}</text>
                    </g>
                    {/*<g class="tick" opacity="1" transform="translate(850,0)">*/}
                    {/*	<line stroke="currentColor" y2="-6"></line>*/}
                    {/*	<text fill="currentColor" y="-9" dy="0em">90%</text>*/}
                    {/*</g>*/}
                    <g class="tick" opacity="1" transform="translate(900,0)">
                        <line stroke="currentColor" y2="-6"></line>
                        <text fill="currentColor" y="-9" dy="0em">{xaxis[5]}</text>
                    </g>
                </g>
                {/* Y Axis */}
                <g transform="translate(400,0)" fill="none" font-size="16" font-family="arial" text-anchor="end">
                    <path class="domain" stroke="currentColor" d="M0,30V100"></path>
                    {/* A */}
                    <g class="tick" opacity="1" transform="translate(0,45)">
                        <line stroke="currentColor" x2="-6"></line>
                        <text fill="currentColor" x="-9" dy="0.32em">{yaxis[0]}</text>
                    </g>
                    {/* B */}
                    <g class="tick" opacity="1" transform="translate(0,75)">
                        <line stroke="currentColor" x2="-6"></line>
                        <text fill="currentColor" x="-9" dy="0.32em">{yaxis[1]}</text>
                    </g>
                </g>
            </svg>
        </div>
    );
}