import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { authFetch, authPost } from "../../authProvider";
import { LanguageContext } from '../LanguageContext';
import { ViivaGraafi } from "./ViivaGraafi";

/* Constants */
const API_PREFIX = 'api/Simulation/SkenaarioVertailu';
const API_SKENAARIOT_PREFIX = 'api/Simulation/Skenaario';
const API_ORGANISAATIOT_PREFIX = 'api/Inventory/Organisaatio';

const FilterGroup = ({ title, items, onChange, selected }) => {
    return (
        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
            <FormLabel style={{ color: "#800000" }}>{title}</FormLabel>
            <FormGroup>
                {(items || []).map((d, i) => <FormControlLabel key={d.riviAvain} control={<Checkbox checked={(selected || []).indexOf(d.riviAvain) > -1} onChange={(e) => onChange(d.riviAvain, e.target.checked)} />} label={d.nimi || d.skenaarioNimi} />)}
            </FormGroup>
        </FormControl>
    );
}

/* Main Component */
export class SkenaarioVertailu extends React.Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = {
            projektit: [],
            organisaatiot: [],
            skenaariot: [],
            selectedOrganisaatiot: [],
            selectedSkenaariot: [],
            loading: true,
            data: []
        };
    }

    componentDidMount() {
        let skenaariot = [], organisaatiot = [];

        //skenaariot
        authFetch(this.props.pca, API_SKENAARIOT_PREFIX + '/Read')
            .then(response => response.json())
            .then(data => {
                if ((data || {}).error) {
                    this.handleError(data.error);
                } else {
                    skenaariot = (data || []);
                }

                //organisaatiot
                authFetch(this.props.pca, API_ORGANISAATIOT_PREFIX + '/Read')
                    .then(response => response.json())
                    .then(data => {
                        if ((data || {}).error) {
                            this.handleError(data.error);
                        } else {
                            organisaatiot = (data || []);
                        }

                        const s = skenaariot[0]?.riviAvain;

                        this.setState({
                            skenaariot: skenaariot,
                            organisaatiot: organisaatiot,
                            selectedSkenaariot: s ? [s] : [],
                            selectedOrganisaatiot: [],
                            loading: false
                        }, () => this.handleUpdateData());
                    });
            });
    }

    handleFilterGroupChange = (name, id, checked) => {
        const { selectedOrganisaatiot, selectedSkenaariot } = this.state;
        let arr = [];

        if (name === "selectedOrganisaatiot") {
            if (checked) {
                arr = selectedOrganisaatiot.concat([id]);
            } else {
                arr = selectedOrganisaatiot.filter(d => d !== id);
            }
        } else {
            if (checked) {
                arr = selectedSkenaariot.concat([id]);
            } else {
                arr = selectedSkenaariot.filter(d => d !== id);
            }
        }

        this.setState({
            [name]: arr
        });
    }

    handleUpdateData = () => {
        const { selectedOrganisaatiot, selectedSkenaariot } = this.state;

        //raportti
        authPost(this.props.pca, API_PREFIX + '/Read', {
            body: JSON.stringify({
                skenaariot: JSON.stringify(selectedSkenaariot),
                organisaatiot: JSON.stringify(selectedOrganisaatiot),
            })
        })
            .then(response => response.json())
            .then(data => {
                if ((data || {}).error) {
                    this.handleError(data.error);
                } else {
                    const paastot = JSON.parse((data[0] || {}).sarja1 || {});
                    const kulutus = JSON.parse((data[0] || {}).sarja2 || {});
                    const items = JSON.parse((data[0] || {}).skenaariot || {});

                    this.setState({
                        paastot: paastot,
                        kulutus: kulutus,
                        data: items,
                        loading: false,
                    });
                }
            });
    }

    render() {
        const { dictionary } = this.context;
        const { organisaatiot, skenaariot, selectedSkenaariot, selectedOrganisaatiot, data, paastot, kulutus } = this.state;

        return (
            <div style={{ padding: 10 }}>
                <div style={{ overflowY: "scroll", height: "90vh" }}>
                    {this.state.loading ? (
                        <CircularProgress />
                    ) : (
                        <Grid container spacing={1}>
                                <Grid item sm={0} md={1} display={{ sm: 'none', md: 'block' }}></Grid>
                            <Grid item sm={7} md={7}>
                                {/* Päästöt and Kulutus Graafit */}
                                <ViivaGraafi
                                    title={dictionary.Simulation.SkenaarioVertailu.Charts[0].title}
                                    tooltip={dictionary.Simulation.SkenaarioVertailu.Charts[0].tooltip}
                                    series={data}
                                    data={paastot || []}
                                    width={800}
                                    height={300}
                                />

                                <ViivaGraafi
                                    title={dictionary.Simulation.SkenaarioVertailu.Charts[1].title}
                                    tooltip={dictionary.Simulation.SkenaarioVertailu.Charts[1].tooltip}
                                    series={data}
                                    data={kulutus || []}
                                    width={800}
                                    height={300}
                                />
                            </Grid>

                            <Grid item sm={5} md={3}>
                                <Paper>
                                    {/* Skenaariosuodatin */}
                                    <div style={{ padding: 16 }}>
                                        <FilterGroup
                                            title="Skenaariot"
                                            items={skenaariot}
                                            selected={selectedSkenaariot}
                                            onChange={(id, checked) => this.handleFilterGroupChange("selectedSkenaariot", id, checked)}
                                        />
                                    </div>

                                    {/* Organisaatiosuodatin */}
                                    <div style={{ padding: 16 }}>
                                        <FilterGroup
                                            title="Organisaatiot"
                                            items={organisaatiot}
                                            selected={selectedOrganisaatiot}
                                            onChange={(id, checked) => this.handleFilterGroupChange("selectedOrganisaatiot", id, checked)}
                                        />
                                    </div>

                                    {/* Päivitys */}
                                    <div style={{ padding: 32 }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={this.handleUpdateData}
                                        >
                                            {dictionary.Simulation.SkenaarioVertailu.Update}
                                        </Button>
                                    </div>
                                </Paper>
                            </Grid>
                                <Grid item sm={0} md={1} display={{ sm: 'none', md: 'block' }}></Grid>
                        </Grid>
                    )}
                </div>
            </div>
        );
    }
}