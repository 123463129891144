import React from 'react';
import { LanguageContext } from './LanguageContext';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MailIcon from '@mui/icons-material/Mail';

export class LocalMenu extends React.Component {
    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        this.state = {
            features: [],
            menuOpen: false,
            anchorEl: null,
            searchText: "",
            title: props.title,
            menuItems: props.menuItems,
            selectedId: props.selectedId,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedId !== this.props.selectedId
            || prevProps.title !== this.props.title) {
            this.setState({
                title: this.props.title,
                menuItems: this.props.menuItems,
                selectedId: this.props.selectedId,
            });
        }
    }

    handleOpen = (e) => {
        this.setState({
            menuOpen: true,
            anchorEl: e.currentTarget
        })
    };

    handleClose = (e) => {
        this.setState({
            menuOpen: false,
            anchorEl: null
        })
    };

    handleSelect = value => event => {
        this.props.onSelectChange(value);
        this.handleClose();
    }

    render() {
        const { userLanguage, dictionary } = this.context;
        const { onlyTopLevel } = this.props;
        const { title, menuItems, selectedId, menuOpen, anchorEl } = this.state;

        const report = (menuItems || []).filter(d => (d.id == selectedId))[0] || {};

        return (
            <AppBar elevation={4} style={{ background: "#fff", height: 56, paddingLeft: 58 }}>
                <Toolbar style={{ minHeight: 56 }}>
                    <Typography style={{ color: "#212121", fontWeight: "bold" }} variant="subtitle1" component="div">
                        {title}
                    </Typography>

                    {menuItems && !onlyTopLevel ? (
                        <div style={{ display: "flex" }}>
                            <NavigateNextIcon fontSize="small" sx={{ margin: "auto", width: 24, color: "#212121" }} />
                            <div
                                onClick={this.handleOpen}
                                style={{ width: 380, backgroundColor: "#ddd", display: "flex" }}
                                sx={{ textTransform: "none" }}
                            >
                                <Typography style={{ marginLeft: 6, paddingTop: 4, color: "#212121", fontWeight: "bold" }} variant="subtitle1" component="div">
                                    {report.name}
                                </Typography>
                                <Box sx={{ flexGrow: 1 }} />
                                {menuItems.length > 1 ? (
                                    <div style={{ borderLeft: "1px solid #ccc", paddingTop: 4 }}>
                                        {menuOpen ? <ExpandLess sx={{ margin: "auto", width: 24, color: "#212121" }} /> : <ExpandMore sx={{ width: 24, color: "#212121" }} />}
                                    </div>
                                ) : null}
                            </div>
                            <Menu anchorEl={anchorEl} open={menuOpen} onClose={this.handleClose}>
                                {(menuItems || []).map((d) => (<MenuItem key={d.id} value={d.id} sx={{ width: 380, backgroundColor: (d.id == selectedId) ? "#ddd" : "inherit" }} onClick={this.handleSelect(d.id)}>{d.name}</MenuItem>))}
                            </Menu>
                        </div>
                    ) : null}

                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { sm: 'none', md: 'flex' } }}>
                        <Typography style={{ marginRight: 16, color: "#212121" }} variant="caption" component="div">
                            {report.info}
                        </Typography>
                    </Box>
                    <Box sx={{ display: { sm: 'none', lg: 'flex' } }}>
                        <Button color="primary" variant="contained" sx={{ color: "#fff", height: 36 }} href={dictionary.LocalMenu.ContactLink} target="_blank">{dictionary.LocalMenu.Contact}</Button>
                    </Box>
                    <Box sx={{ display: { sm: 'flex', lg: 'none' } }}>
                        <IconButton href={dictionary.LocalMenu.ContactLink} target="_blank" size="large">
                            <MailIcon color="primary" />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar >
        );
    }
}
