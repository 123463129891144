import * as React from 'react';


export class MuutosHistoria extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null,
            loading: true
            }
    }

    render() {

        return (
            <div>
                <div style={{ margin: "5px" }}>
                    <p>Versio 1.2.0 (2024-07-15)</p>
                    <ul>
                        <li>Lisätty uusi raportointiominaisuus (tiketit #45, #47)</li>
                        <li>Parannettu hakutoiminnon suorituskykyä (tiketit #50, #53)</li>
                        <li>Korjattu virhe, joka aiheutti kaatumisen kirjautumisen yhteydessä (tiketti #60)</li>
                        <li>Päivitetty käyttöohjeet ja dokumentaatio vastaamaan uusia toimintoja</li>
                    </ul>
                </div>

                <div>
                    <p>Versio 1.1.1 (2024-05-20)</p>
                    <ul>
                        <li>Korjattu tietoturva-aukko käyttäjän salasanojen käsittelyssä (tiketti #32)</li>
                        <li>Päivitetty kolmannen osapuolen kirjastot uusimpiin versioihin (tiketit #35, #36)</li>
                    </ul>
                </div>
            </div>
        );
    }
}

