import * as React from 'react';
import { Link } from 'react-router-dom';
import {
    Card,
    CardHeader,
    CardActionArea,
    Typography,
    Grid,
    Button,
    Stack,
    Box,
    Divider,
    Paper,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CircleIcon from '@mui/icons-material/Circle';
import { LanguageContext } from './LanguageContext';
import { authFetch } from "./../authProvider";
import { LocalMenu } from "./LocalMenu";
import { MuutosHistoria } from "./MuutosHistoria";
import CircularProgress from '@mui/material/CircularProgress';
import IconArrowUp from './Icons/IconArrowUp';
import IconArrowDown from './Icons/IconArrowDown';

const InfoBars = ({ items, handleLink, icon }) => {
    return (
        <Stack spacing={1}>
            {(items || []).map((d, i) => {
                return (
                    <Box
                        key={i}
                        sx={{
                            boxShadow: 1,
                            borderRadius: 1,
                            padding: "10px",
                            backgroundColor: "white",
                            display: "flex",
                        }}
                    >
                        <Box sx={{ paddingTop: "6px", paddingRight: "16px" }}>
                            {icon}
                        </Box>
                        <Box sx={{ paddingTop: "8px", width: 100 }}>
                            <Typography variant="body2" component="div" style={{ color: "#646464" }}>
                                {d.date}
                            </Typography>
                        </Box>
                        <Divider sx={{ m: 1 }} orientation="vertical" flexItem />
                        <Box sx={{ paddingTop: "5px", flexGrow: 1 }}>
                            <Typography variant="subtitle1" component="div">
                                {d.title}
                            </Typography>
                        </Box>
                        <Box>
                            <Button variant="outlined" onClick={() => handleLink(d.title, d.content)}>{d.button}</Button>
                        </Box>
                    </Box>
                );
            })}
        </Stack>
    );
}

const QuickLinks = ({ items, handleLink }) => {
    return (
        <Stack spacing={2} divider={<Divider flexItem />}>
            {(items || []).map((d, i) => {
                return (
                    <Box
                        key={i}
                        sx={{
                            paddingTop: "10px",
                            '&:hover': {
                                textDecoration: "underline"
                            }
                        }}
                    >
                        {d.linkUrl.toString().substring(0, 6) === 'SIIRRY' ?
                            (<Link style={{ color: "#800000", textDecoration: "none" }} onClick={() => handleLink(d.linkUrl)}>
                                {d.button}
                            </Link>) :
                            (d.linkUrl.indexOf("https://") > -1) ?
                                (<Box>
                                    <a style={{ color: "#800000", textDecoration: "none" }} href={d.linkUrl} target="_blank" rel="noreferrer">
                                        {d.button}
                                    </a>
                                    <OpenInNewIcon className="textLabel" style={{ color: "#800000", marginLeft: 8, marginBottom: -3, fontSize: "16px" }} />
                                </Box>) :
                                (<Link style={{ color: "#800000", textDecoration: "none" }} to={(d.linkUrl || "/")}>
                                    {d.button}
                                </Link>)
                        }
                    </Box>
                );
            })}
        </Stack>
    );
}

const Featured = ({ item, handleLink }) => {
    return (
        <Box
            sx={{
                boxShadow: 6,
                borderRadius: 1,
                padding: "32px",
                backgroundColor: "#fff",
                display: "flex"
            }}
        >
            <Box sx={{ flexGrow: 1 }}>
                <Typography style={{ fontWeight: "bold" }} variant="subtitle1" component="div">
                    {item.title}
                </Typography>
                <Typography variant="body1" component="div">
                    {item.subtitle}
                </Typography>
            </Box>
            <Box>
                <Button variant="outlined" onClick={handleLink}>{item.button}</Button>
            </Box>
        </Box>
    );

}

const Media = ({ item, last, handleLink }) => {

    if (item.highlight === "ICON_ARROW_UP") item.highlight = <IconArrowUp />
    if (item.highlight === "ICON_ARROW_DOWN") item.highlight = <IconArrowDown />

    return (
        <Card
            className="hexagon"
            key={item.title}
            elevation={3}
            sx={{
                //width: 225,
                width: 325,
                display: { sm: (last ? 'none' : 'flex'), lg: 'flex' },
                "& .hoverArrow": {
                    display: "none",
                },
                border: "1px solid #ddd",
                '&:hover': {
                    boxShadow: 2,
                    "& .hoverArrow": {
                        display: "block",
                    },
                },

            }}
        >
            <CardActionArea
                onClick={(e) => {
                    if (item.linkUrl.toString().substring(0, 6) === 'SIIRRY') {
                        handleLink(item.linkUrl);
                    } else {
                        window.open("?type=report&id=" + item.linkUrl + "&parent=tietopalvelu_" + item.serviceId, "_self");
                    }
                }}
            >
                <CardHeader
                    title={
                        <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                            {item.title}
                        </Typography>
                    }
                    subheader={
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            <Box sx={{ marginTop: "-1px", paddingRight: "6px" }}>
                                <CircleIcon style={{ fontSize: 12, color: item.subtitleColor }} />
                            </Box>
                            <Box>
                                <Typography variant="body1" component="div" style={{ fontSize: 14, color: item.subtitleColor }}>
                                    {item.subtitle}
                                </Typography>
                            </Box>
                        </Box>
                    }
                    style={{ height: 80, alignItems: "start" }}
                />
            </CardActionArea>
        </Card>
    );
}

const Banner = ({ items, size, handleLink }) => {

    if (items && size) {
        items = items.length > size ? items.slice(0, size) : items;

        return (
            <div className="imageContainer">
                <Stack direction="row" spacing={2} className="container">
                    {(items || []).map((d, i) => {
                        return (
                            <Media key={i} item={d} last={(i === size - 1)} handleLink={handleLink} />
                        )
                    })}
                </Stack>
            </div>
        );
    } else {
        return null;
    }
}

export class Home extends React.Component {
    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        this.state = {
            data: null,
            loading: true,
            openHistory: false,
            openMessage: false,
            messageTitle: '',
            messageText: ''

        }

        //Karuselli
        authFetch(this.props.pca, 'api/Karuselli/Sisalto')
            .then(response => response.json())
            .then(data => {
                if (Array.isArray(data) && data[0].data) {
                    this.setState({
                        data: JSON.parse(data[0].data),
                        loading: false
                    });
                }
            });
    }

    handleSIIRRY = (funcStr) => {
        const params = (funcStr.replace("SIIRRY", "").replace("(", "").replace(")", "").split(",") || []).map(d => parseInt(d));
        if (this.props?.onSelectionChange && (params || []).length > 2) {
            this.props.onSelectionChange(params[0], params[1], params[2]);
        }
    }

    handleHistory = () => {
        this.setState({ openHistory: !this.state.openHistory })
    };

    handleOpenMessage = (title, msg) => {
        this.setState({
            openMessage: true,
            messageTitle: title || '',
            messageText: msg || ''
        })
    };

    handleCloseMessage = () => {
        this.setState({
            openMessage: false,
        })
    };

    render() {
        const { userLanguage, dictionary } = this.context;
        const { data, loading, openHistory, openMessage, messageTitle, messageText } = this.state;

        const lang = userLanguage === 'fi' ? 'fi' : 'en';

        return (
            <React.Fragment>
                <LocalMenu
                    title={dictionary.Home.Title}
                    onSearchClicked={(d) => console.log(d)}
                />
                <Box sx={{
                    overflowY: "auto",
                    height: "calc(100vh - 58px)",
                    marginTop: "56px",
                    minHeight: "600px"
                }}>
                    <div style={{ backgroundColor: "#999", height: 100 }}>
                    </div>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <div style={{ maxWidth: 1200, margin: "auto", fontSize: 16, paddingLeft: 24, paddingRight: 24 }}>
                            <div style={{ marginTop: -100, padding: "40px 0px 40px 0px" }}>
                                {(((data || {}).featured || {})[lang] || []).length > 0 ? <Featured item={data.featured[lang][0]} handleLink={this.handleHistory} /> : null}
                            </div>
                            <div style={{ marginBottom: 32 }}>
                                <Typography style={{ marginTop: -12, marginBottom: 14 }} variant="h5" component="div">
                                    {(((data || {}).carousel || {})[lang] || []).length > 0 ? data?.header[lang]?.title : null}
                                </Typography>
                                {(((data || {}).carousel || {})[lang] || []).length > 0 ? <Banner items={data.carousel[lang]} size={4} handleLink={this.handleSIIRRY} /> : null}
                            </div>
                            <Grid container spacing={6}>
                                <Grid item xs={9}>
                                    <Typography style={{ marginBottom: 14 }} variant="h5" component="div">
                                        {data?.header[lang]?.titleAnnouncements}
                                    </Typography>
                                    {(((data || {}).announcements || {})[lang] || []).length > 0 ?
                                        (<InfoBars items={data.announcements[lang]} handleLink={this.handleOpenMessage} icon={<NewReleasesOutlinedIcon style={{ fontSize: 24, color: "#800000" }} />} />) :
                                        (<Paper elevation={0} variant="outlined" style={{ height: "83%" }}>
                                            <Box sx={{ m: 10, color: "#646464", textAlign: "center" }}>{dictionary.Karuselli.NoAnnouncements}</Box>
                                        </Paper>)}
                                </Grid>
                                <Grid item xs={3} >
                                    <Typography style={{ marginBottom: 8 }} variant="h5" component="div">
                                        {data?.header[lang]?.titleLinks}
                                    </Typography>
                                    {(((data || {}).quicklinks || {})[lang] || []).length > 0 ? <QuickLinks items={data.quicklinks[lang]} handleLink={this.handleSIIRRY} /> : <div></div>}
                                </Grid>
                            </Grid>

                            <Grid container spacing={6} style={{ marginTop: "10px", marginBottom: "30px" }}>
                                <Grid item xs={9}>
                                    <Typography style={{ marginBottom: 14 }} variant="h5" component="div">
                                        {data?.header[lang]?.titleFooter}
                                    </Typography>
                                    {(((data || {}).footer || {})[lang] || []).length > 0 ?
                                        (<InfoBars items={((data || {}).footer || {})[lang]} handleLink={this.handleSIIRRY} icon={<AccessTimeIcon style={{ fontSize: 24, color: "#800000" }} />} />) :
                                        (<Paper elevation={0} variant="outlined" style={{ height: "83%" }}>
                                            <Box sx={{ m: 10, color: "#646464", textAlign: "center" }}>{dictionary.Karuselli.NoComingTasks}</Box>
                                        </Paper>)}
                                </Grid>
                            </Grid>
                        </div>
                    )}
                </Box>

                {/* Historiapopupikkuna */}
                <Dialog
                    open={openHistory}
                    onClose={this.handleHistory}
                >
                    <DialogTitle>
                        {dictionary.Karuselli.HistoryTitle}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ color: "#646464" }}>
                            <MuutosHistoria />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" style={{ color: "#fff" }} onClick={this.handleHistory}>
                            {dictionary.Close}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Ilmoituspopupikkuna */}
                <Dialog
                    open={openMessage}
                    onClose={this.handleCloseMessage}
                >
                    <DialogTitle>
                        {messageTitle}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ color: "#646464" }}>
                            <p>{messageText}</p>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" style={{ color: "#fff" }} onClick={this.handleCloseMessage}>
                            {dictionary.Close}
                        </Button>
                    </DialogActions>
                </Dialog>

            </React.Fragment>
        );
    }
}

