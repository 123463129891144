import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
    VictoryChart,
    VictoryScatter,
    VictoryLine,
    VictoryTheme,
    VictoryLegend,
    VictoryTooltip,
    VictoryAxis,
    VictoryLabel,
    VictoryContainer
} from "victory";

// Utility to format numbers on the axis
const formatAxisNumber = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9) return +(n / 1e9).toFixed(1) + "B";
};

export const ViivaGraafi = ({ title, tooltip, series, data, width, height }) => {
    const [lines, setLines] = React.useState([]);
    const [domain, setDomain] = React.useState([]);
    const [legend, setLegend] = React.useState([]);
    const [chartWidth, setChartWidth] = React.useState(width);
    const [chartHeight, setChartHeight] = React.useState(height);

    React.useEffect(() => {
        if (data && series) {
            const ds = (data || [])[0] || [];
            const dm = ds.map(d => d.x);  // Assuming 'x' is the year or time data
            setLines(data || []);
            setDomain(dm);
            setLegend(series);
        }
    }, [data, series]);

    React.useEffect(() => {
        setChartWidth(width);
    }, [width]);

    React.useEffect(() => {
        setChartHeight(height);
    }, [height]);

    return (
        <Box sx={{ padding: "2px" }} >
            <Box sx={{ maxWidth: "100%", border: "1px solid #ccc" }} >
                <Box
                    sx={{ backgroundColor: "#ddd", color: "#000", padding: "6px" }}
                >
                    <Typography style={{ fontSize: "16px" }}>{title}</Typography>
                </Box>
                <Box
                    sx={{ padding: "6px" }}
                >
                    <VictoryChart
                        animate={false}
                        padding={{ top: 30, bottom: 50, left: 70, right: 70 }}
                        theme={VictoryTheme.material}
                        domainPadding={20}
                        width={chartWidth}
                        height={chartHeight}
                    >
                        {/* Legend for the lines */}
                        <VictoryLegend
                            x={parseInt(chartWidth / 4)}
                            y={10}
                            orientation="horizontal"
                            colorScale={["#800000", "#000"]}
                            data={legend}
                        />

                        {/* X-Axis */}
                        <VictoryAxis
                            tickValues={domain}
                            tickFormat={(t) => `${t}`}  // Assuming 't' is the year or numeric value
                            fixLabelOverlap
                        />

                        {/* Y-Axis for Line 1 */}
                        <VictoryAxis
                            dependentAxis
                            orientation="left"
                            axisLabelComponent={<VictoryLabel dy={-50} />}
                            tickFormat={(t) => formatAxisNumber(t)}
                        />

                        {lines.map((d, i) => {
                            return (
                                <VictoryLine
                                    key={i}
                                    data={d}
                                />
                            );
                        })}
}
                        {lines.map((d, i) => {
                            return (
                                <VictoryScatter
                                    key={i}
                                    size={5}
                                    data={d}
                                    labels={({ datum }) => (tooltip || '').replace('$x$', datum.x).replace('$y$', datum.y)}
                                    labelComponent={<VictoryTooltip />}
                                />
                            );
                        })}
                    </VictoryChart>
                </Box>
            </Box>
        </Box>
    );
};