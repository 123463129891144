import React from 'react';
import Box from '@mui/material/Box';
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from '@mui/material/Typography';
//import { LanguageContext } from '../LanguageContext';
import {
    VictoryChart,
    VictoryScatter,
    VictoryLine,
    VictoryBar,
    VictoryTheme,
    VictoryLegend,
    VictoryTooltip,
    VictoryAxis,
    VictoryLabel
} from "victory";

/* Näkymäkohtaiset muuttujat ja vakiot */
const formatAxisNumber = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};

export const PystyGraafi = ({ title, barsLegend, linesLegend, data, barSeries, lineSeries, width, height, tooltip1, tooltip2 }) => {
    //const context = React.useContext(LanguageContext);
    const [bars, setBars] = React.useState([]);
    const [barWidth, setBarWidth] = React.useState(0);
    const [lines, setLines] = React.useState([]);
    const [domain, setDomain] = React.useState([]);
    const [chartWidth, setChartWidth] = React.useState(width);
    const [chartHeight, setChartHeight] = React.useState(height);
    const [maxBar, setMaxBar] = React.useState(0);
    const [maxLine, setMaxLine] = React.useState(0);

    React.useEffect(() => {
        if (data) {
            const ds1 = JSON.parse(data[barSeries]) || [];
            const ds2 = JSON.parse(data[lineSeries]) || [];
            const ds3 = ds1.map(d => d.x);
            setBars(ds1);
            setLines(ds2);
            setDomain(ds3);
        }
    }, [data, barSeries, lineSeries]);

    React.useEffect(() => {
        if (domain.length > 0) {
            const band = domain.length * 2;
            setBarWidth(parseInt(chartWidth / band));
        }
    }, [domain, chartWidth]);

    React.useEffect(() => {
        setChartWidth(width);
    }, [width]);

    React.useEffect(() => {
        setChartHeight(height);
    }, [height]);

    React.useEffect(() => {
        setMaxBar(Math.max(...bars.map(o => o.y)));
    }, [bars]);

    React.useEffect(() => {
        setMaxLine(Math.max(...lines.map(o => o.y)));
    }, [lines]);

    return (
        <FormControl sx={{ m: 3, height: height }} variant="standard">
            <FormLabel style={{ color: "#800000" }}>{title}</FormLabel>
            <FormGroup>
                <VictoryChart
                    animate={false}
                    padding={{ top: 30, bottom: 30, left: 150, right: 150 }}
                    theme={VictoryTheme.material}
                    domainPadding={10}
                    width={chartWidth}
                    height={chartHeight}
                >
                    <VictoryLegend x={parseInt(chartWidth - 150)} y={parseInt(chartHeight / 2 - 20)}
                        orientation="vertical"
                        colorScale={["#800000", "#000"]}
                        data={[
                            { name: barsLegend }, { name: linesLegend }
                        ]}
                    />
                    <VictoryAxis
                        orientation="left"
                        tickValues={domain}
                        fixLabelOverlap
                    />
                    <VictoryAxis
                        dependentAxis
                        orientation="top"
                        label={barsLegend}
                        axisLabelComponent={<VictoryLabel dy={-50} />}
                        tickValues={[0.25, 0.5, 0.75, 1]}
                        tickFormat={(t) => formatAxisNumber(t * maxBar)}
                    />
                    <VictoryAxis
                        dependentAxis
                        orientation="bottom"
                        label={linesLegend}
                        axisLabelComponent={<VictoryLabel dy={50} />}
                        tickValues={[0.25, 0.5, 0.75, 1]}
                        tickFormat={(t) => formatAxisNumber(t * maxLine)}
                    />
                    {/* Dataset Bars   barWidth={barWidth}*/}
                    <VictoryBar horizontal
                        style={{ data: { fill: "#800000" } }}
                        
                        data={bars}
                        labels={({ datum }) => (tooltip1 || '').replace('$x$', datum.x).replace('$y$', datum.y)}
                        labelComponent={<VictoryTooltip />}
                        y={(datum) => maxBar !== 0 ? datum.y / maxBar : 0}
                    />
                    {/* Dataset Lines */}
                    <VictoryLine horizontal
                        data={lines}
                        style={{ data: { strokeDasharray: 5 } }}
                        y={(datum) => maxLine !== 0 ? datum.y / maxLine : 0}
                    />
                    <VictoryScatter
                        style={{ data: { fill: "#000" } }}
                        size={5}
                        data={lines}
                        labels={({ datum }) => (tooltip2 || '').replace('$x$', datum.x).replace('$y$', datum.y)}
                        labelComponent={<VictoryTooltip />}
                        y={(datum) => maxLine !== 0 ? datum.y / maxLine : 0}
                    />
                </VictoryChart>
            </FormGroup>
        </FormControl>
    );
}
