import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import TiedostoAdd from "./TiedostoAdd";
import TiedostoEdit from "./TiedostoEdit";
import CircularProgress from '@mui/material/CircularProgress';
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import cloneDeep from 'lodash.clonedeep';
import SortColButton from "./SortColButton";

import { LocalMenu } from './LocalMenu';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import GetAppIcon from '@mui/icons-material/GetApp';
import IconPowerPoint from './Icons/IconPowerPoint';
import IconWord from './Icons/IconWord';
import IconExcel from './Icons/IconExcel';
import IconPDF from './Icons/IconPDF';
import { FeatureTrim } from './FeatureTrim';
import Dialog from "@mui/material/Dialog";

import { authFetch, authPost, authPostFile } from "./../authProvider";
import { LanguageContext } from './LanguageContext';
import moment from 'moment';

/* Näkymäkohtaiset muuttujat ja vakiot */
const API_PREFIX = 'api/Tiedosto';
const API_LIITE_PREFIX = 'api/Tiedosto/Liite';
const API_KOODIT_PREFIX = 'api/Koodi';
const API_TOIMINNOT_PREFIX = 'api/Toiminto/Entiteetti/Read/Tiedosto';

const getIcon = (name) => {
    return (
        name == "excel" ? IconExcel :
            name == "word" ? IconWord :
                name == "powerpoint" ? IconPowerPoint :
                    name == "pdf" ? IconPDF :
                        UploadFileIcon
    );
}

const b64toBlob = (b64Data, contentType, sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

const getLiite = (id, that) => {
    authFetch(that.props.pca, API_LIITE_PREFIX + '/Read/' + id)
        .then(response => response.json())
        .then(data => {
            if ((data || {}).error) {
                console.error(data.message);
            } else {
                if ((data || [])[0]) {
                    that.setState({ filesLoading: --that.state.filesLoading });
                    const blob = b64toBlob(data[0].blobdata, data[0].contentType);
                    const blobUrl = URL.createObjectURL(blob);

                    downloadLiite(data[0].blobname, blobUrl);
                }
            }
        });
}

const downloadLiite = (name, url) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
}

const default_columns = [
    {
        label: "RiviAvain",
        name: "riviAvain",
        options: {
            filter: false,
            display: false
        }
    },
    {
        label: "Käyttöoikeus",
        name: "empty",
        options: {
            filter: false,
            display: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                return parseInt(((tableMeta || {}).rowData || [])[0]) > 0 ? "Yleinen" : "Asiakaskohtainen";
            }
        }
    },
    {
        label: "TiedostoNimi",
        name: "tiedostoNimi",
        options: {
            filter: false,
            display: true,
            customBodyRender: function (value, tableMeta, updateValue) {
                const badge = ((tableMeta || {}).rowData || [])[10]; //Nosto
                const id = ((tableMeta || {}).rowData || [])[16]; //LiiteId

                return (
                    <div
                        onClick={() => {
                            getLiite(id, that);
                            that.setState({ filesLoading: 1 });
                        }}
                        style={{
                            display: "flex",
                        }}
                    >
                        <Link style={{ color: "#800000", textDecoration: "none", cursor: "pointer" }} >{value || ""}</Link>
                        {badge ? <Typography style={{ color: "#fff", backgroundColor: "#800000", fontSize: "12px", padding: 3, borderRadius: 5, marginLeft: 20, maxHeight: 16 }} component="div" > {badge}</Typography > : null}
                    </div>
                );
            }
        }
    },
    {
        label: "TietopalveluAvain",
        name: "tietopalveluAvain",
        options: {
            filter: false,
            display: false,
            customBodyRender: function (value, tableMeta, updateValue) {
                return tietopalvelut[value];
            }
        },
        lookuplist: 'tietopalvelut'
    },
    {
        label: "Toiminto_Id",
        name: "toiminto_Id",
        options: {
            filter: false,
            display: false,
            customBodyRender: function (value, tableMeta, updateValue) {
                return toiminnot[value];
            }
        },
        lookuplist: 'toiminnot'
    },
    {
        label: "AiheAvain",
        name: "aiheAvain",
        options: {
            filter: false,
            display: false,
            customBodyRender: function (value, tableMeta, updateValue) {
                return aiheet[value];
            }
        },
        lookuplist: 'aiheet'
    },
    {
        label: "Vuosi",
        name: "vuosi",
        options: {
            filter: true,
            display: true
        },
    },
    {
        label: "TiedostotyyppiAvain",
        name: "tiedostotyyppiAvain",
        options: {
            filter: false,
            customBodyRender: function (value, tableMeta, updateValue) {
                const Icon = getIcon((tiedostotyypit[value] || '').toLowerCase());

                return (
                    <Icon style={{ fontSize: "24px" }} />
                );
            }
        },
        lookuplist: 'tiedostotyypit'
    },
    {
        label: "Kieli",
        name: "kieli",
        options: {
            filter: false,
            display: false
        }
    },
    {
        label: "TiedostoKuvaus",
        name: "tiedostoKuvaus",
        options: {
            filter: false,
            display: false
        }
    },
    {
        label: "Nosto",
        name: "nosto",
        options: {
            filter: false,
            display: false
        }
    },
    {
        label: "GlobalId",
        name: "globalId",
        options: {
            filter: false,
            display: false,
            customBodyRender: function (value, tableMeta, updateValue) {
                return "Email: /?type=file&id=" + value + "&download=0 TAI PowerBI: https://<domain>/lataa/?id=" + value;
            },
        }
    },
    {
        label: "Luotu",
        name: "created",
        options: {
            filter: false,
            display: false,
            customBodyRender: function (value, tableMeta, updateValue) {
                var date = moment(value).format("D.M.YYYY, HH:mm");
                return moment(value).isValid() ? date : "";
            },
        }
    },
    {
        label: "Muokattu",
        name: "updated",
        options: {
            filter: false,
            display: true,
            customBodyRender: function (value, tableMeta, updateValue) {
                var date = moment(value).format("D.M.YYYY");
                return moment(value).isValid() ? date : "";
            },
        }
    },
    {
        label: "Username",
        name: "username",
        options: {
            filter: false,
            display: false
        }
    },
    {
        label: "Active",
        name: "active",
        options: {
            filter: false,
            display: false
        }
    },
    {
        label: "LiiteId",
        name: "liiteId",
        options: {
            filter: false,
            display: false
        }
    },
];

/* Perusmuuttujat ja vakiot */
const PROFIILI_GET = 'api/Profiili/Read';
const PROFIILI_PUT = 'api/Profiili/Update';

//TODO:Theme
const SubmenuItem = ({ id, title, description, badge, onClick, external, selected }) => {
    return (
        <Card
            square
            onClick={() => onClick(id)}
            style={{ marginBottom: 16, padding: 8 }}
            sx={{
                borderRight: (selected ? "3px solid #800000" : null),
                backgroundColor: (selected ? "#80000042" : "transparent"),
                boxShadow: 0,
                "&:hover": {
                    backgroundColor: '#8000001e'
                }
            }}
        >
            <div style={{ display: "flex", cursor: "pointer" }} >
                <Typography style={{ color: "#000", fontWeight: "bold", fontSize: "16px" }} component="div">
                    {title}
                </Typography>
                {external ? <OpenInNewIcon className="textLabel" style={{ marginLeft: 8, marginTop: 5, color: "#000", fontSize: "16px" }} /> : null}
                <Box sx={{ flexGrow: 1 }} />
                {badge ? <Typography style={{ color: "#000", backgroundColor: "#800000", fontSize: "12px", padding: "3px 6px 3px 6px", borderRadius: 5 }} component="div">{badge}</Typography> : null}
            </div>
        </Card>

    );
}

//TODO:Theme
const SubmenuContainer = ({ items }) => {
    return (
        <div style={{ padding: 16 }}>
            {items}
        </div>);
}

var rowId;
var selectedRows = [];
var items = [];
var koodit = {};
var tietopalvelut = {};
var toiminnot = {};
var aiheet = {};
var tiedostotyypit = {};

// Query parameters
const searchParams = new URLSearchParams(document.location.search);

var that;

/* Luokka */
export class Tiedosto extends Component {
    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        // State alustukset
        this.state = {
            features: [],
            rowsSelected: [],
            lookupLists: {
                koodit: koodit,
                tietopalvelut: tietopalvelut,
                toiminnot: toiminnot,
                aiheet: aiheet,
                tiedostotyypit: tiedostotyypit,
            },
            loading: true,
            rowNum: 25,
            errorShow: false,
            errorTitle: '',
            errorBody: '',
            selectedId: null,
            lang: "fi",
            filesLoading: 0,
            asiakasAvain: null,
            sortOrder: { name: 'riviAvain', direction: 'asc' },
        };

        //Query parameters
        const parent = searchParams.get('parent');

        //Bindit
        this.swapCols = this.swapCols.bind(this);
        this.addNewItem = this.addNewItem.bind(this);

        //Features
        authFetch(this.props.pca, 'api/Toiminto/Read')
            .then(response => response.json())
            .then(data => {
                var features = data.filter(d => d.aktivoitu).map(d => d.toiminto); //.filter((v, i, a) => a.indexOf(v) == i); //uniikit
                this.setState({ features: features })
            });

        //navi
        authFetch(this.props.pca, API_PREFIX + '/Navigaatio')
            .then(response => response.json())
            .then(data => {
                const navData = (data || [])[0]?.data;
                let navi = [];
                if (navData) {
                    let a;
                    try {
                        a = JSON.parse(navData);
                    } catch (e) {
                        return console.error(e); // error in the above string (in this case, yes)!
                    }
                    navi = Array.isArray(a) ? a : [a];
                }

                //koodit
                authFetch(this.props.pca, API_KOODIT_PREFIX + '/Read')
                    .then(response => response.json())
                    .then(data => {
                        data.forEach(function (d, i) {
                            let nimi = d.koodiNimi; //TODO: fi/en
                            koodit[d.koodiAvain] = (nimi || 'ei tietoja');

                            /*
                            * TODO: Kovakoodatut arvot
                                Id	Koodiryhma
                                3   Tietopalvelu
                                6	Aihe
                                7	Tiedostotyyppi
                            */

                            if (d.koodiryhmaAvain == 3) tietopalvelut[d.koodiAvain] = koodit[d.koodiAvain];
                            if (d.koodiryhmaAvain == 6) aiheet[d.koodiAvain] = koodit[d.koodiAvain];
                            if (d.koodiryhmaAvain == 7) tiedostotyypit[d.koodiAvain] = koodit[d.koodiAvain];

                        });

                        //toiminnot
                        authFetch(this.props.pca, API_TOIMINNOT_PREFIX)
                            .then(response => response.json())
                            .then(data => {
                                data.forEach(function (d, i) {
                                    toiminnot[d.toimintoId] = (d.toiminto || 'ei tietoja');
                                });

                                //Items
                                let id = parent || (((navi || [])[0] || {}).id || "-1");

                                authFetch(this.props.pca, API_PREFIX + '/Tietopalvelu/Read/' + id)
                                    .then(response => response.json())
                                    .then(data => {
                                        items = data

                                        //Profiili
                                        authFetch(this.props.pca, PROFIILI_GET)
                                            .then(response => response.json())
                                            .then(data => {
                                                const cols = null;
                                                const hidden_cols = null;
                                                let rowNum = (((JSON.parse((data || [])[0].uI_Settings) || {}).rownum || {}).tiedosto || []); // TODO: muuta avain
                                                if (rowNum.length > 0) rowNum = JSON.parse(rowNum);
                                                else rowNum = 25;

                                                const columns = get_ordered_columns(cols, hidden_cols);

                                                this.setState({
                                                    lookupLists: {
                                                        koodit: koodit,
                                                        tietopalvelut: tietopalvelut,
                                                        toiminnot: toiminnot,
                                                        aiheet: aiheet,
                                                        tiedostotyypit: tiedostotyypit,
                                                    },
                                                    columns: columns,
                                                    data: items,
                                                    navi: navi,
                                                    loading: false,
                                                    rowNum: rowNum,
                                                    selectedId: id,
                                                    asiakasAvain: data?.asiakasAvain,
                                                });
                                            });
                                    });

                                this.handleLogEvent(null, "Reports Link", "Click", id + ";/tiedostot"); //TietopalveluAvain;Url

                            });
                    });
            });

        that = this;
    }

    handleLogEvent = (e, target, action, info) => {
        if (e) e.preventDefault();

        authPost(this.props.pca, 'api/EventLog/Create', {
            body: JSON.stringify({
                target: target,
                action: action,
                info: info
            })
        })
    }

    updateData = () => {
        const { selectedId } = this.state;

        authFetch(this.props.pca, API_PREFIX + '/Tietopalvelu/Read/' + selectedId)
            .then(response => response.json())
            .then(data => {
                this.setState({ data: data, rowsSelected: [] })
            });

        this.handleLogEvent(null, "Reports Link", "Click", selectedId + ";/tiedostot"); //TietopalveluAvain;Url
    }

    swapCols = (index) => {
        if (index > 1) {
            var cols = cloneDeep(this.state.columns);
            var d = this.state.data;
            [cols[index - 1], cols[index]] = [cols[index], cols[index - 1]];
            this.setState({ columns: cols });
            var setting = { "key": "columns.tiedosto", "value": JSON.stringify(cols.map(function (d) { return d.name; })).split('"').join('""') }; // TODO: muuta avain
            authPost(this.props.pca, PROFIILI_PUT, {
                body: JSON.stringify(setting)
            });
        }
    };

    changeRownum = (num) => {
        this.setState({ rowNum: num })

        var setting = { "key": "rownum.tiedosto", "value": JSON.stringify(num).split('"').join('""') }; // TODO: muuta avain
        authPost(this.props.pca, PROFIILI_PUT, {
            body: JSON.stringify(setting)
        })
    };

    hiddenCols = (changedColumn, action) => {
        var cols = cloneDeep(this.state.columns);
        var hidden = [];

        for (var i = 0; i < cols.length; i++) {
            if (!(cols[i].options))
                cols[i].options = {};

            if (cols[i].name == changedColumn)
                cols[i].options.display = (action === "add" ? true : false)

            if (cols[i].options.display == false) {
                hidden.push(cols[i].name);
            }
        }

        if (action === "add") {
            hidden.splice(hidden.indexOf(changedColumn), 1);
        }
        else if (action === "remove") {
            hidden.push(changedColumn);
        }

        this.setState({ columns: cols });
        var setting = { "key": "hiddencolumns.tiedosto", "value": (hidden.length > 0 ? JSON.stringify(hidden).split('"').join('""') : "[]") }; // TODO: muuta avain
        authPost(this.props.pca, PROFIILI_PUT, {
            body: JSON.stringify(setting)
        })
    };

    handleError = (error) => {
        const { onError } = this.props;

        var title = '', body = ''
        if (error === 4) {
            title = this.context.dictionary.ErrorDialog.Error4.title;
            body = this.context.dictionary.ErrorDialog.Error4.body;
        } else if (error === 5) {
            title = this.context.dictionary.ErrorDialog.Error5.title;
            body = this.context.dictionary.ErrorDialog.Error5.body;
        } else {
            title = this.context.dictionary.ErrorDialog.Error.title;
            body = this.context.dictionary.ErrorDialog.Error.body;
        }

        onError(title, body);
    }

    handleErrorClose = () => this.setState({ errorShow: false });

    addNewItem = (newrow, file) => {
        // ADD
        const formData = new FormData();
        formData.append('file', file);
        formData.append('json', JSON.stringify(newrow));

        authPostFile(this.props.pca, API_LIITE_PREFIX + '/Create', {
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                if ((data || {}).error) {
                    this.handleError(data.error);
                } else {
                    this.updateData();
                    this.handleMessage(this.context.dictionary.Message.Add)
                }
            });
    }

    editItem = (newrow, file) => {
        // EDIT
        if (!file) {
            authPost(this.props.pca, API_PREFIX + '/Update', {
                body: JSON.stringify(newrow)
            })
                .then(response => response.json())
                .then(data => {
                    if ((data || {}).error) {
                        this.handleError(data.error);
                    } else {
                        this.updateData();
                        this.handleMessage(this.context.dictionary.Message.Edit)
                    }
                });
        } else {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('json', JSON.stringify(newrow));

            authPostFile(this.props.pca, API_LIITE_PREFIX + '/Update', {
                body: formData
            })
                .then(response => response.json())
                .then(data => {
                    if ((data || {}).error) {
                        this.handleError(data.error);
                    } else {
                        this.updateData();
                        this.handleMessage(this.context.dictionary.Message.Edit)
                    }
                });
        }
    }

    handleMessage = (msg) => {
        const { onMessage } = this.props;
        onMessage(msg);
    }

    handleMenuLink = (value) => {
        this.setState({
            selectedId: value,
        }, () => this.updateData());
    }

    handleSortChange = (changedColumn, direction) => {
        this.setState({
            sortOrder: { name: changedColumn, direction },
        });
    };

    render() {
        const { userLanguage, dictionary } = this.context;
        const { errorShow, errorTitle, errorBody, selectedId, features, asiakasAvain } = this.state;

        var cols = cloneDeep(this.state.columns);
        if ((cols || []).length > 0) {
            for (var i = 0; i < default_columns.length; i++) {
                for (var j = 0; j < cols.length; j++) {
                    if (cols[j].label == default_columns[i].label) {
                        cols[j].label = dictionary.Tiedosto.Columns[i] //TODO: Muuta avain
                    }
                }
            }
        }

        const editMode = (features.indexOf("UI_Tiedosto_Kirjoitus") > -1);

        var options = {
            textLabels: dictionary.MuiDataTable.textLabels,
            selectableRowsHeader: true,
            selectableRowsOnClick: false,
            print: editMode,
            download: editMode,
            viewColumns: editMode,
            selectableRows: "multiple",
            filterType: 'multiselect',
            responsive: "vertical",
            tableBodyHeight: "calc(100vh - 230px)",
            rowsPerPageOptions: [5, 10, 25, 50, 100],
            rowsPerPage: this.state.rowNum,
            jumpToPage: true,
            rowsSelected: this.state.rowsSelected,
            sortOrder: this.state.sortOrder,
            onColumnSortChange: this.handleSortChange,
            onViewColumnsChange: (changedColumn, action) => {
                //this.hiddenCols(changedColumn, action);
            },
            onRowSelectionChange: (currentRowsSelected, allRows, rowsSelected) => {
                const { data } = this.state;
                rowId = null;
                if (rowsSelected.length > 0) {
                    rowId = data[rowsSelected[0]].riviAvain; //TODO: Muuta avain
                }
                this.setState({ rowsSelected: rowsSelected });
            },
            onChangeRowsPerPage: (num) => {
                this.changeRownum(num);
            },
            // Huomioi samat arvot ja sen jölkeen lajitellaan ensimmäisen sarakkeen (id) mukaan
            customSort: (data, colIndex, order) => {
                const { columns, lookupLists } = this.state;

                return data.sort((a, b) => {
                    if (a.data[colIndex] == b.data[colIndex]) {
                        // samanarvoisissa riveissä otetaan riviavainjärjestys
                        return ((a.data[0] || -9e6) < (b.data[0] || -9e6) ? -1 : 1) * (order === 'desc' ? 1 : -1);
                    } else if (columns[colIndex].options.lookuplist) {
                        // lookuplist lajittelu
                        const list = lookupLists[columns[colIndex].options.lookuplist];
                        var aa = (list[a.data[colIndex]] || ''), bb = (list[b.data[colIndex]] || '');

                        return ((parseInt(!/[^0-9]/i.test(aa) ? aa : 'x') || aa.toString()) < (parseInt(!/[^0-9]/i.test(bb) ? bb : 'x') || bb.toString()) ? -1 : 1) * (order === 'desc' ? 1 : -1);
                    } else {
                        // normaali lajittelu
                        return ((a.data[colIndex] || -9e6) < (b.data[colIndex] || -9e6) ? -1 : 1) * (order === 'desc' ? 1 : -1);
                    }
                });
            },
            customToolbarSelect: (selRows, displayData, setSelectedRows) => {
                const { data } = this.state;

                if ((selRows.data || []).length > 0) {
                    selectedRows = selRows.data.map(d => (d.dataIndex));
                    rowId = (data[selectedRows[0]] || {}).riviAvain; //TODO: Muuta avain
                }

                const handleDelete = () => {
                    var data = cloneDeep(this.state.data);

                    if (window.confirm("Haluatko varmasti poistaa seuraavat kohteet: " + selectedRows.map(d => data[d].riviAvain).join(", "))) {
                        var requests = []
                        for (var i = 0; i < selectedRows.length; i++) {
                            requests.push(
                                authPost(this.props.pca, API_PREFIX + '/Delete/' + data[selectedRows[i]].riviAvain, { method: 'delete' }) //TODO: Muuta avain
                            );
                        }
                        Promise.all(requests)
                            .then(() => {
                                setSelectedRows([]);
                                this.updateData();
                                this.handleMessage(`(${selectedRows.length}) ${dictionary.Message.Delete}`)
                            });
                    }
                }

                const handleCopy = () => {
                    const { data } = this.state;
                    if (window.confirm("Haluatko varmasti kopioida seuraavat kohteet: " + selectedRows.map(d => data[d].riviAvain).join(", "))) {
                        var requests = []
                        for (var i = 0; i < selectedRows.length; i++) {
                            const row = data[selectedRows[i]];
                            var newrow = {
                                RiviAvain: 1, //dummy-arvo
                                TietopalveluAvain: row.tietopalveluAvain,
                                Toiminto_Id: row.toiminto_Id,
                                AiheAvain: row.aiheAvain,
                                Vuosi: row.vuosi,
                                TiedostotyyppiAvain: row.tiedostotyyppiAvain,
                                Kieli: row.kieli,
                                Jarjestys: row.jarjestys,
                                TiedostoNimi: row.tiedostoNimi + "-kopio",
                                TiedostoKuvaus: row.tiedostoKuvaus,
                                Nosto: row.nosto,
                                Created: row.created,
                                Updated: row.updated,
                                Username: row.username,
                                Active: row.active
                            };
                            requests.push(
                                authPost(this.props.pca, API_PREFIX + '/Create', { body: JSON.stringify(newrow) })
                            );
                        }
                        Promise.all(requests)
                            .then(() => {
                                this.updateData();
                                this.handleMessage(`(${selectedRows.length}) ${dictionary.Message.Copy}`)
                            });
                    }
                }

                const handleDownload = () => {
                    const data = cloneDeep(this.state.data);
                    let num_files = this.state.filesLoading || 0;
                    var requests = []
                    for (var i = 0; i < selectedRows.length; i++) {
                        num_files++;
                        requests.push(
                            //authFetch(this.props.pca, API_LIITE_PREFIX + '/Read/' + data[selectedRows[i]].liiteId) //TODO: Muuta avain
                            getLiite(data[selectedRows[i]].liiteId, this)
                        );
                    }
                    Promise.all(requests);
                    this.setState({ filesLoading: num_files });
                }

                return (
                    <div className={"custom-toolbar-select"}>

                        {(selectedRows.length < 2) ? (
                            <React.Fragment>
                                <div className={"custom-toolbar-select"}>
                                    <FeatureTrim features={features} feature={"UI_Tiedosto_Luku"}>
                                        <Tooltip title={dictionary.Toolbar.Download}>
                                            <IconButton onClick={handleDownload}>
                                                <GetAppIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </FeatureTrim>
                                    <FeatureTrim features={features} feature={"UI_Tiedosto_Kirjoitus"}>
                                        <TiedostoEdit onEditRow={this.editItem} lookupLists={this.state.lookupLists} Daatta={this.state.data} rowId={rowId} orgId={asiakasAvain} />
                                        <Tooltip title={dictionary.Toolbar.Copy}>
                                            <IconButton onClick={handleCopy}>
                                                <FileCopyIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={dictionary.Toolbar.Delete}>
                                            <IconButton onClick={handleDelete}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </FeatureTrim>
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <FeatureTrim features={features} feature={"UI_Tiedosto_Luku"}>
                                    <Tooltip title={dictionary.Toolbar.Download}>
                                        <IconButton onClick={handleDownload}>
                                            <GetAppIcon />
                                        </IconButton>
                                    </Tooltip>
                                </FeatureTrim>
                                <FeatureTrim features={features} feature={"UI_Tiedosto_Kirjoitus"}>
                                    <Tooltip title={dictionary.Toolbar.Copy}>
                                        <IconButton onClick={handleCopy}>
                                            <FileCopyIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={dictionary.Toolbar.Delete}>
                                        <IconButton onClick={handleDelete}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </FeatureTrim>
                            </React.Fragment>
                        )}

                    </div>
                );
            },
            customToolbar: () => {
                return (
                    <React.Fragment>
                        <FeatureTrim features={features} feature={"UI_Tiedosto_Kirjoitus"}>
                            <TiedostoAdd onAddNewRow={this.addNewItem} lookupLists={this.state.lookupLists} Daatta={this.state.data} orgId={asiakasAvain} />
                        </FeatureTrim>
                    </React.Fragment>
                );
            }

        };

        const items = (this.state.navi || []).map((d, i) => {
            let title = userLanguage == 'fi' ? d.titleFi : d.titleEn;
            return (
                <SubmenuItem key={i} id={d.id} title={title} description={title} badge={null} onClick={this.handleMenuLink} selected={selectedId == d.id} />
            );
        });

        const tabletitle = (this.state.navi || []).filter(d => selectedId == d.id).map((d, i) => (userLanguage == 'fi' ? d.titleFi : d.titleEn))[0] || "";

        //dictionary.<komponentin nimi>.Title
        return (
            <React.Fragment>
                <LocalMenu
                    title={(dictionary.Tiedosto.Title || "")}
                    onSearchClicked={(d) => alert("Haku " + d)}
                />
                <div style={{ marginTop: 56, display: "flex" }}>
                    <Box style={{ width: 280 }} >
                        <Box
                            sx={{
                                height: "100vh",
                                width: 280,
                                backgroundColor: "#fff",
                                boxShadow: 8,
                                minHeight: "600px"
                            }}
                        >
                            <SubmenuContainer items={items} />
                        </Box>

                    </Box>
                    <Box style={{ width: "100%", padding: 10, minHeight: 600 }} >
                        {(this.state.loading) ? (
                            <div style={{ width: "100%", height: "100vh" }}>
                                <CircularProgress />
                            </div>
                        ) : (
                            <MUIDataTable
                                title={tabletitle}
                                data={this.state.data}
                                columns={cols}
                                options={options}
                            />
                        )
                        }
                    </Box>
                </div>
                <Dialog open={(this.state.filesLoading > 0)}>
                    <div style={{
                        padding: 20,
                        width: 300,
                        height: 200
                    }}>
                        < div style={{
                            margin: 0,
                            marginTop: 20,
                            textAlign: "center"
                        }}>
                            <CircularProgress />
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}

function get_ordered_columns(list, hidden) {
    var columns = [];

    if (!Array.isArray(list)) {
        list = default_columns.map((c) => (c.name));
    }

    list.forEach(function (el) {
        columns.push((default_columns.filter(function (d) { return d.name == el; }) || [])[0]);
        if (hidden != null) {
            if (!(columns[columns.length - 1].options))
                columns[columns.length - 1].options = {};
            if (hidden.indexOf(columns[columns.length - 1].name) > -1) {
                columns[columns.length - 1].options.display = false;
            } else {
                columns[columns.length - 1].options.display = true;
            }
        }
    });

    return columns;
}