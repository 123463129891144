/* eslint-disable import/first */
import React from 'react';
import { MsalContext } from "@azure/msal-react";
import { Routes, Route } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { AdminRaportti } from './components/AdminRaportti';
import { AdminProfiili } from './components/AdminProfiili';
import { AdminRooli } from './components/AdminRooli';
import { AdminRoolit } from './components/AdminRoolit';
import { AdminRooliOikeudet } from './components/AdminRooliOikeudet';
import { AdminAsiakas } from './components/AdminAsiakas';
import { AdminToiminnot } from './components/AdminToiminnot';
import { AdminRooliProfiili } from './components/AdminRooliProfiili';
import { Koodi } from './components/Koodi';
import { Koodiryhma } from './components/Koodiryhma';
import { Help } from './components/Help';
import { ReportContent } from './components/ReportContent';
import { MarkdownContent } from './components/MarkdownContent';
import { ServiceInfo } from './components/ServiceInfo';
import { Terms } from './components/Terms';
import { TermsView } from './components/TermsView';
import { Tiedosto } from './components/Tiedosto';
import { Karuselli } from './components/Karuselli';
import { NotFound } from './components/NotFound';
import { TiedostoLatausSivu } from './components/TiedostoLatausSivu';
import { Testi } from './components/Simulation/Testi';
import { SkenaarioVertailu } from './components/Simulation/SkenaarioVertailu';
import { enqueueSnackbar } from 'notistack'
import ErrorDialog from './components/ErrorDialog';


export default class App extends React.Component {
    static contextType = MsalContext;

    // Valinnan v�litys
    state = {
        selected: null,
        parentId: null,
        itemId: null,

        alertOpen: false,
        errorMessage: '',
        errorTitle: ''
    }

    handleSelectionChange = (value) => {
        this.setState({ selected: value });
    }

    handleHomeSelectionChange = (parentId, itemId, menuId) => {
        this.setState({
            parentId: parentId,
            itemId: itemId,
            menuId: menuId
        }, () => {
            setTimeout(() => {
                this.setState({
                    parentId: null,
                    itemId: null,
                    menuId: null
                });
            }, 500)
        });
    }

    handleError = (title, message, onClose) => {
        this.setState({
            alertOpen: true,
            errorMessage: message,
            errorTitle: title
        })
    }

    handleErrorClose = () => {
        this.setState({ alertOpen: false })
    }

    handleMessage = (msg) => {
        enqueueSnackbar(msg);
    }

    render() {
        const { instance } = this.context;
        const { alertOpen, errorMessage, errorTitle } = this.state;

        const CustomComponent = ({ Comp }) => {
            return (<Comp pca={instance} onMessage={this.handleMessage} onError={this.handleError} />)
        }

        return (
            <React.Fragment>
                <Routes>
                    <Route path="/lataa" element={<TiedostoLatausSivu pca={instance} />} />
                    <Route element={<Layout selected={this.state.selected} parentId={this.state.parentId} itemId={this.state.itemId} menuId={this.state.menuId} pca={instance} />}>
                        <Route exact path='/' element={<Home onSelectionChange={this.handleHomeSelectionChange} pca={instance} onMessage={this.handleMessage} onError={this.handleError} />} />
                        <Route path='/asiakas' element={<CustomComponent Comp={AdminAsiakas} />} />
                        <Route path='/raportit' element={<CustomComponent Comp={AdminRaportti} />} />
                        <Route path='/profiilit' element={<CustomComponent Comp={AdminProfiili} />} />
                        <Route path='/roolit' element={<CustomComponent Comp={AdminRooli} />} />
                        <Route path='/profiiliroolit' element={<CustomComponent Comp={AdminRoolit} />} />
                        <Route path='/profiilienroolit' element={<CustomComponent Comp={AdminRooliProfiili} />} />
                        <Route path='/roolioikeudet' element={<CustomComponent Comp={AdminRooliOikeudet} />} />
                        <Route path='/toiminnot' element={<CustomComponent Comp={AdminToiminnot} />} />
                        <Route path='/koodi' element={<CustomComponent Comp={Koodi} />} />
                        <Route path='/koodiryhma' element={<CustomComponent Comp={Koodiryhma} />} />
                        <Route exact path="/report" element={<ReportContent selected={this.state.selected} onSelectionChange={this.handleSelectionChange} pca={instance} onMessage={this.handleMessage} onError={this.handleError} />} />
                        <Route path="/serviceinfo" element={<CustomComponent Comp={ServiceInfo} />} />
                        <Route path="/page" element={<CustomComponent Comp={MarkdownContent} />} />
                        <Route path="/terms" element={<CustomComponent Comp={Terms} />} />
                        <Route path="/termsview" element={<CustomComponent Comp={TermsView} />} />
                        <Route path="/help" element={<CustomComponent Comp={Help} />} />
                        <Route path="/tiedostot" element={<CustomComponent Comp={Tiedosto} />} />
                        <Route path="/karuselli" element={<CustomComponent Comp={Karuselli} />} />
                        <Route path="/testi" element={<CustomComponent Comp={Testi} />} />
                        <Route path="/skenaariovertailu" element={<CustomComponent Comp={SkenaarioVertailu} />} />
                        <Route path="*" element={<CustomComponent Comp={NotFound} />} />
                    </Route>
                </Routes>
                <ErrorDialog
                    open={alertOpen}
                    title={errorTitle}
                    body={errorMessage}
                    onClose={this.handleErrorClose}
                />
            </React.Fragment>
        );
    }
}