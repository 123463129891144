import React from "react";
import PropTypes from 'prop-types';
import Autocomplete from '@mui/lab/Autocomplete';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { DropzoneArea } from 'react-mui-dropzone';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import IconPowerPoint from './Icons/IconPowerPoint';
import IconWord from './Icons/IconWord';
import IconExcel from './Icons/IconExcel';
import IconPDF from './Icons/IconPDF';

import { withStyles } from "@mui/styles";
import { LanguageContext } from './LanguageContext';

const defaultToolbarStyles = {
    iconButton: {
    },
};

const MIME_TYPES = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/pdf"
];

class TiedostoAdd extends React.Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            RiviAvain: null,
            TietopalveluAvain: null,
            Toiminto_Id: null,
            AiheAvain: null,
            Vuosi: null,
            TiedostotyyppiAvain: null,
            Kieli: "fi",
            TiedostoNimi: null,
            TiedostoKuvaus: null,
            Nosto: null,
            Created: null,
            Updated: null,
            Username: null,
            Active: null,
            tietopalvelut: (Object.keys(props.lookupLists.tietopalvelut).map(function (d) { return { value: d, label: props.lookupLists.tietopalvelut[d] }; }) || []),
            filteredtietopalvelut: (Object.keys(props.lookupLists.tietopalvelut).map(function (d) { return { value: d, label: props.lookupLists.tietopalvelut[d] }; }) || []),
            toiminnot: (Object.keys(props.lookupLists.toiminnot).map(function (d) { return { value: d, label: props.lookupLists.toiminnot[d] }; }) || []),
            filteredtoiminnot: (Object.keys(props.lookupLists.toiminnot).map(function (d) { return { value: d, label: props.lookupLists.toiminnot[d] }; }) || []),
            aiheet: (Object.keys(props.lookupLists.aiheet).map(function (d) { return { value: d, label: props.lookupLists.aiheet[d] }; }) || []),
            filteredaiheet: (Object.keys(props.lookupLists.aiheet).map(function (d) { return { value: d, label: props.lookupLists.aiheet[d] }; }) || []),
            tiedostotyypit: (Object.keys(props.lookupLists.tiedostotyypit).map(function (d) { return { value: d, label: props.lookupLists.tiedostotyypit[d] }; }) || []),
            filteredtiedostotyypit: (Object.keys(props.lookupLists.tiedostotyypit).map(function (d) { return { value: d, label: props.lookupLists.tiedostotyypit[d] }; }) || []),
            files: [],
            shared: false,
        };
    }

    static propTypes = {
        onAddNewRow: PropTypes.func,
        classes: PropTypes.object,
        blurOnSelect: PropTypes.bool
    };

    handleSave = () => {
        const { files } = this.state;

        files.forEach((f, i) => {
            this.props.onAddNewRow({
                RiviAvain: 1, //dummy-arvo
                TietopalveluAvain: this.state.TietopalveluAvain,
                Toiminto_Id: (this.state.shared ? this.state.Toiminto_Id : null),
                AiheAvain: this.state.AiheAvain,
                Vuosi: this.state.Vuosi,
                TiedostotyyppiAvain: this.handleFileType(f.type),
                Kieli: this.state.Kieli,
                TiedostoNimi: (i > 0 ? f.name : this.state.TiedostoNimi),
                TiedostoKuvaus: this.state.TiedostoKuvaus,
                Nosto: this.state.Nosto,
                Created: this.state.Created,
                Updated: this.state.Updated,
                Username: this.state.Username,
                Active: this.state.Active
            }, f);
        })

        this.setState({ open: false });
    }

    handleFileType = (filetype) => {
        // Lisätietoja: https://yuvaleros.github.io/material-ui-dropzone/
        const { tiedostotyypit } = this.state;

        switch (filetype) {
            case MIME_TYPES[0]:
            case MIME_TYPES[1]:
                return (tiedostotyypit.filter(d => d.label == "Word") || [])[0].value;
            case MIME_TYPES[2]:
            case MIME_TYPES[3]:
                return (tiedostotyypit.filter(d => d.label == "Excel") || [])[0].value;
            case MIME_TYPES[4]:
            case MIME_TYPES[5]:
                return (tiedostotyypit.filter(d => d.label == "PowerPoint") || [])[0].value;
            case MIME_TYPES[6]:
                return (tiedostotyypit.filter(d => d.label == "PDF") || [])[0].value;
            default:
                return null
        }
    }

    handleFilesChange = (files) => {
        if (files.length > 0) {
            this.setState({
                files: files,
                TiedostoNimi: files[0].name,
            });
        } else {
            this.setState({
                files: []
            });
        }
    }

    render() {
        const { dictionary } = this.context;
        const { lookupLists, orgId } = this.props;
        const { tietopalvelut, toiminnot, aiheet, tiedostotyypit } = this.state;

        const handleChange = name => event => {
            this.setState({ [name]: event.target.value });
        };

        const handleClickOpen = () => {
            this.setState({
                open: true,
                filteredtietopalvelut: tietopalvelut,
                filteredtoiminnot: toiminnot,
                filteredaiheet: aiheet,
                filteredtiedostotyypit: tiedostotyypit
            });
        }

        const handleListChange = (event, newValue, item) => {
            if (newValue != null) {
                this.setState({ [item]: newValue.value })
            }
            else {
                this.setState({ [item]: null })
            }
        }

        const handleListStateChange = list => {
            const data = (Object.keys(lookupLists[list]).map(function (d) { return { value: d, label: lookupLists[list][d] }; }) || [])
            this.setState({ [list]: data })
        };

        const handleClose = () => {
            this.setState({ open: false });
        }

        return (
            <React.Fragment>
                <Tooltip title={dictionary.Toolbar.NewRow}>
                    <IconButton onClick={handleClickOpen}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>

                <Dialog
                    open={this.state.open}
                    onClose={handleClose}
                    maxWidth="sm"
                    fullWidth
                    scroll="body"
                >
                    <DialogTitle>{dictionary.Tiedosto.Title}</DialogTitle>
                    <DialogContent>
                        <DropzoneArea
                            onChange={this.handleFilesChange.bind(this)}
                            acceptedFiles={MIME_TYPES}
                            showPreviews={true}
                            showPreviewsInDropzone={false}
                            dropzoneClass="document-dropzone"
                            useChipsForPreview
                            filesLimit={20}
                            maxFileSize={250000000}
                            previewText={dictionary.Tiedosto.Dropzone.PreviewTitle}
                            dropzoneText={dictionary.Tiedosto.Dropzone.AddTitle}
                        />

                        <input type="hidden" value={this.state.RiviAvain} />
                        <TextField
                            margin="dense"
                            id="TiedostoNimi"
                            label={dictionary.Tiedosto.Columns[2]}
                            type="text"
                            fullWidth
                            value={this.state.TiedostoNimi}
                            onChange={handleChange('TiedostoNimi')}
                            disabled={(this.state.files.length > 1)}
                        />
                        <div style={{ display: 'flex', paddingTop: "10px" }} >
                            <Autocomplete
                                onChange={(event, newValue) => { handleListChange(event, newValue, 'TietopalveluAvain') }}
                                onInputChange={() => { handleListStateChange('tietopalvelut') }}
                                options={tietopalvelut}
                                getOptionLabel={(option) => option.label}
                                style={{ width: "100%" }}
                                renderInput={(params) => <TextField  {...params} required={true} variant="standard" label={dictionary.Tiedosto.Columns[3]} />}
                            />
                        </div>
                        {orgId == 1 ? ( // Vain Pääorg
                            <React.Fragment>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.shared}
                                            onChange={(e) => this.setState({ shared: e.target.checked })}
                                            color="primary"
                                        />
                                    }
                                    label={this.state.shared ? dictionary.Tiedosto.Feature.Shared : dictionary.Tiedosto.Feature.NotShared}
                                    labelPlacement="end"
                                />
                                {this.state.shared ? (
                                    <div style={{ display: 'flex', paddingTop: "10px" }} >
                                        <Autocomplete
                                            options={toiminnot}
                                            getOptionLabel={(option) => option.label}
                                            style={{ width: 400 }}
                                            onChange={(event, newValue) => { handleListChange(event, newValue, 'Toiminto_Id') }}
                                            onInputChange={() => { handleListStateChange('toiminnot') }}
                                            renderInput={(params) => (
                                                <TextField {...params} required={true} variant="standard" label={dictionary.Tiedosto.Columns[4]} />
                                            )}
                                        />
                                    </div>
                                ) : null}
                            </React.Fragment>
                        ) : null}
                        <div style={{ display: 'flex', paddingTop: "10px" }} >
                            <Autocomplete
                                onChange={(event, newValue) => { handleListChange(event, newValue, 'AiheAvain') }}
                                onInputChange={() => { handleListStateChange('aiheet') }}
                                options={aiheet}
                                getOptionLabel={(option) => option.label}
                                style={{ width: "100%" }}
                                renderInput={(params) => <TextField  {...params} required={true} variant="standard" label={dictionary.Tiedosto.Columns[5]} />}
                            />
                        </div>
                        {/*<TextField*/}
                        {/*    margin="dense"*/}
                        {/*    id="Vuosi"*/}
                        {/*    label={dictionary.Tiedosto.Columns[6]}*/}
                        {/*    type="text"*/}
                        {/*    fullWidth*/}
                        {/*    value={this.state.Vuosi}*/}
                        {/*    onChange={handleChange('Vuosi')}*/}
                        {/*/>*/}
                        <TextField
                            margin="dense"
                            id="TiedostoKuvaus"
                            label={dictionary.Tiedosto.Columns[9]}
                            type="text"
                            fullWidth
                            value={this.state.TiedostoKuvaus}
                            onChange={handleChange('TiedostoKuvaus')}
                        />
                        <TextField
                            margin="dense"
                            id="Nosto"
                            label={dictionary.Tiedosto.Columns[10]}
                            type="text"
                            fullWidth
                            value={this.state.Nosto}
                            onChange={handleChange('Nosto')}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={handleClose}>
                            {dictionary.Close}
                        </Button>
                        <Button variant="contained" color="primary" style={{ color: "#fff" }} onClick={this.handleSave}>
                            {dictionary.Save}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(defaultToolbarStyles, { name: "TiedostoAdd" })(TiedostoAdd);
