import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { authFetch, authPost } from "../../authProvider";
import { LanguageContext } from '../LanguageContext';
import { StaattinenVaakagraafi } from "./StaattinenVaakagraafi";

/* N�kym�kohtaiset muuttujat ja vakiot */
const API_PREFIX = 'api/ESRS/ESRSOrganisaatio';
const API_ORGANISAATIOT_PREFIX = 'api/Inventory/Organisaatio';
const API_VUODET_PREFIX = 'api/ESRS/ESRSOrganisaatio/Vuodet';
const ERSR_LYHENNE = 'S3';

/* Main Component */
export class AiheS3 extends React.Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            errorShow: false,
            errorTitle: '',
            errorBody: '',
            organisaatio: [],
            vuodet: [],
            data: null,
            canSave: false
        };
    }

    componentDidMount() {
        let organisaatiot = [], vuodet = [];
        //organisaatiot
        authFetch(this.props.pca, API_ORGANISAATIOT_PREFIX + '/Read')
            .then(response => response.json())
            .then(data => {
                data.forEach((d, i) => {
                    organisaatiot.push({ value: d.riviAvain, label: d.nimi });
                });

                //organisaatiot
                authFetch(this.props.pca, API_VUODET_PREFIX + '/Read/' + (organisaatiot || [])[0]?.value + '/' + ERSR_LYHENNE)
                    .then(response => response.json())
                    .then(data => {
                        let foundCurrent = false;
                        const y = (new Date()).getFullYear();
                        data.forEach((d, i) => {
                            vuodet.push({ value: d.riviAvain, label: d.vuosi });
                            if (d.vuosi === y) {
                                foundCurrent = true;
                            }
                        });

                        if (!foundCurrent) {
                            vuodet.push({ value: y, label: y });
                        }

                        //Items
                        authFetch(this.props.pca, API_PREFIX + '/Read/' + (organisaatiot || [])[0]?.value + '/' + ERSR_LYHENNE + '/' + vuodet[0].value)
                            .then(response => response.json())
                            .then(data => {
                                this.setState({
                                    organisaatiot: organisaatiot,
                                    organisaatio: (organisaatiot || [])[0]?.value,
                                    vuodet: vuodet,
                                    vuosi: vuodet[0].value,
                                    data: data,
                                    loading: false,
                                    Tieto1: (data || [])[0]?.tieto1
                                })
                            });
                    });
            });
    }

    handleCancel = () => {
        const data = (this.state.data || [])[0];

        if (data) {
            this.setState({
                Tieto1: data.Tieto1,
                Tieto2: data.Tieto2,
                Tieto3: data.Tieto3,
                Tieto4: data.Tieto4,
                Tieto5: data.Tieto5,
                Tieto6: data.Tieto6,
                Tieto7: data.Tieto7,
                Tieto8: data.Tieto8,
                Tieto9: data.Tieto9,
            });
        }
    }

    handleMessage = (msg) => {
        const { onMessage } = this.props;
        onMessage(msg);
    }

    handleSave = () => {
        const newrow = {
            RiviAvain: 1,
            OrganisaatioAvain: this.state.organisaatio,
            ESRSLyhenne: ERSR_LYHENNE,
            RaportointiAika: new Date(this.state.vuosi, 11, 31),
            Tieto1: this.state.Tieto1,
            Tieto2: this.state.Tieto2,
            Tieto3: this.state.Tieto3,
            Tieto4: this.state.Tieto4,
            Tieto5: this.state.Tieto5,
            Tieto6: this.state.Tieto6,
            Tieto7: this.state.Tieto7,
            Tieto8: this.state.Tieto8,
            Tieto9: this.state.Tieto9,
        };

        const method = (this.state.data || []).length > 0 ? '/Update' : '/Create';
        authPost(this.props.pca, API_PREFIX + method, {
            body: JSON.stringify(newrow)
        })
            .then(response => response.json())
            .then(data => {
                if ((data || {}).error) {
                    this.handleError(data.error);
                } else {
                    authFetch(this.props.pca, API_PREFIX + '/Read/' + this.state.organisaatio + '/' + ERSR_LYHENNE + '/' + this.state.vuosi)
                        .then(response => response.json())
                        .then(data => {
                            this.setState({
                                data: data,
                                canSave: false,
                                loading: false
                            })
                            const msg = (method === '/Create') ? this.context.dictionary.Message.Add : this.context.dictionary.Message.Edit
                            this.handleMessage(msg)
                        });
                }
            });
    }

    handleChange = name => event => {
        const data = (this.state.data || [])[0];
        const val = event.target.value;

        this.setState({
            [name]: val,
            canSave: (data && val && data[name] !== val) || (!data && val)
        });
    };

    handleError = (error) => {
        const { onError } = this.props;

        var title = '', body = ''
        if (error === 4) {
            title = this.context.dictionary.ErrorDialog.Error4.title;
            body = this.context.dictionary.ErrorDialog.Error4.body;
        } else if (error === 5) {
            title = this.context.dictionary.ErrorDialog.Error5.title;
            body = this.context.dictionary.ErrorDialog.Error5.body;
        } else {
            title = this.context.dictionary.ErrorDialog.Error.title;
            body = this.context.dictionary.ErrorDialog.Error.body;
        }

        onError(title, body);
    }

    handleErrorClose = () => this.setState({ errorShow: false });

    handleListChange = name => event => {
        const val = event.target.value;

        this.setState({
            [name]: val
        });
    }

    render() {
        const { loading, canSave, Tieto1, Tieto2, Tieto3, Tieto4, Tieto5, Tieto6, Tieto7, Tieto8, Tieto9, organisaatiot, vuodet, organisaatio, vuosi } = this.state;
        const { dictionary } = this.context;

        return (
            <div style={{ padding: 10 }} >
                <div style={{ overflowY: "scroll", height: "90vh" }}>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <h1>{dictionary.ESRS.AiheS3.Label}</h1>
                            </Grid>
                            <Grid item xs={2}>
                                <Select
                                    value={organisaatio}
                                    label={dictionary.ESRS.Navigaatio.Oganisaatio}
                                    onChange={this.handleListChange("organisaatio")}
                                    fullWidth
                                >
                                    {(organisaatiot || []).map((d, i) => (<MenuItem key={i} value={d.value}>{d.label}</MenuItem>))}
                                </Select>
                            </Grid>
                            <Grid item xs={2}>
                                <Select
                                    value={vuosi}
                                    label={dictionary.ESRS.Navigaatio.Vuosi}
                                    onChange={this.handleListChange("vuosi")}
                                    fullWidth
                                >
                                    {(vuodet || []).map((d, i) => (<MenuItem key={i} value={d.value}>{d.label}</MenuItem>))}
                                </Select>
                            </Grid>
                            <Grid item xs={12}>
                                <p>{dictionary.ESRS.AiheS3.Text}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>{dictionary.ESRS.AiheS3.Text1}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>{dictionary.ESRS.AiheS3.Text2}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label={dictionary.ESRS.Navigaatio.Tieto + " #1"}
                                    placeholder={dictionary.ESRS.Navigaatio.Placeholder}
                                    multiline
                                    rows={10}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    onChange={this.handleChange('Tieto1')}
                                    value={Tieto1 || ''}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label={dictionary.ESRS.Navigaatio.Tieto + " #2"}
                                    placeholder={dictionary.ESRS.Navigaatio.Placeholder}
                                    multiline
                                    rows={10}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    onChange={this.handleChange('Tieto2')}
                                    value={Tieto2 || ''}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <p>{dictionary.ESRS.AiheS3.Text3}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>{dictionary.ESRS.AiheS3.Text4}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label={dictionary.ESRS.Navigaatio.Tieto + " #3"}
                                    placeholder={dictionary.ESRS.Navigaatio.Placeholder}
                                    multiline
                                    rows={10}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    onChange={this.handleChange('Tieto3')}
                                    value={Tieto3 || ''}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label={dictionary.ESRS.Navigaatio.Tieto + " #4"}
                                    placeholder={dictionary.ESRS.Navigaatio.Placeholder}
                                    multiline
                                    rows={10}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    onChange={this.handleChange('Tieto4')}
                                    value={Tieto4 || ''}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <p>{dictionary.ESRS.AiheS3.Text5}</p>
                            </Grid>
                            <Grid item xs={6}>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label={dictionary.ESRS.Navigaatio.Tieto + " #5"}
                                    placeholder={dictionary.ESRS.Navigaatio.Placeholder}
                                    multiline
                                    rows={10}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    onChange={this.handleChange('Tieto5')}
                                    value={Tieto5 || ''}
                                />
                            </Grid>
                            <Grid item xs={6}>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ display: "flex" }}>
                                    <Box sx={{ flexGrow: 1 }} />
                                    <Button variant="outlined" color="primary" style={{ margin: 4 }} onClick={this.handleCancel}>
                                        {dictionary.Cancel}
                                    </Button>
                                    <Button variant="contained" color="primary" style={{ color: "#fff", margin: 4 }} disabled={!canSave} onClick={this.handleSave}>
                                        {dictionary.Save}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>

                    )}
                </div>
            </div >
        );
    }
}