import fi from './fi.json';
import en from './en.json';
import ru from './ru.json';
import se from './se.json';

export const dictionaryList = { fi, en, ru, se };

export const languageOptions = {
    fi: 'Suomi',
    en: 'English',
    ru: 'Pусский',
    se: 'Svenska'
};