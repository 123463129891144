import * as React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Grid, Select, MenuList, MenuItem, ListItemText, ListItemIcon, Typography, Tooltip, Menu, Button } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import FeedIcon from '@mui/icons-material/Feed';
import IconButton from '@mui/material/IconButton';
import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { LanguageContext } from './LanguageContext';
import { languageOptions } from './../translations';
import { authFetch, authPost, logOut } from "./../authProvider";
import { FeatureTrim } from './FeatureTrim';
import IconKarpon from './Icons/IconKarpon';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import ErrorDialog from './ErrorDialog';
import { TiedostoLataus } from './TiedostoLataus';
import cloneDeep from 'lodash.clonedeep';

import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import GradingIcon from '@mui/icons-material/Grading';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';

//https://stackoverflow.com/questions/15523514/find-by-key-deep-in-a-nested-array
const findNestedObj = (entireObj, keyToFind, valToFind) => {
    let foundObj;
    JSON.stringify(entireObj, (_, nestedValue) => {
        if (nestedValue && (nestedValue[keyToFind] || '').toString() === (valToFind || '').toString()) {
            foundObj = nestedValue;
        }
        return nestedValue;
    });
    return foundObj;
};

const icons = {
    certification: WorkspacePremiumIcon,
    dataingestion: CopyAllIcon,
    inventory: GradingIcon,
    simulation: TroubleshootIcon,
    verification: VerifiedUserIcon,
    esrs: BookmarkBorderIcon,

    FeedIcon: FeedIcon,
    HelpIcon: HelpIcon,
    HomeIcon: HomeIcon,
    AccountCircleIcon: AccountCircleIcon,
    SettingsIcon: SettingsIcon,
    LogoutIcon: LogoutIcon,
    LanguageIcon: LogoutIcon//LanguageIcon,
}

const MenuTypo = (props) => {
    return <Typography textAlign="center" style={{ color: "#303030" }} > {props.children}</Typography>;
}

const MenuButton = (props) => {
    return <Button size="large" onClick={props.onClick} style={{ fontSize: "16pt", color: "#fff" }}>{props.children}</Button>;
}

const OrganizationSelect = ({ items, value, onChange, title }) => {
    return (
        <div style={{ marginLeft: 20, marginTop: -20, width: 260, padding: 4 }}>
            <Typography color="primary" style={{ fontWeight: "bold", fontSize: "16px" }} component="div">
                {title}
            </Typography>
            <Select
                fullWidth
                color="info"
                sx={{
                    color: "#000",
                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: '#800000',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#800000',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#800000',
                    },
                    '.MuiSvgIcon-root ': {
                        fill: "#800000 !important",
                    }
                }}
                value={value}
                onChange={onChange}
            >
                {items.map((d, i) => (<MenuItem key={i} value={d.profiilitId}>{d.asiakasNimi || "Tieto puuttuu"}</MenuItem>))}
            </Select>
        </div>
    );
}

//TODO:Theme
const ServiceItem = ({ id, title, icon, onClick, external, selected, lang, badge, hasChildren }) => {
    const Icon = Object.keys(icons).indexOf(icon) > -1 ? icons[icon] : icons["HelpIcon"];

    return (
        <MenuItem
            sx={{
                marginTop: "-1px",
                marginBottom: "-1px",
                borderRight: (selected ? "3px solid #ffffff" : null),
                backgroundColor: (selected ? "#ffffff42" : "transparent"),
                boxShadow: 0,
                "&:hover": {
                    backgroundColor: '#ffffff1e'
                }
            }}
            onClick={() => onClick(id)}
        >
            <ListItemIcon style={{ fill: "#fff" }}>
                {icon != "LanguageIcon" ?
                    <Icon style={{ color: "#fff", fontSize: "24px" }} /> :
                    <Typography color="secondary" style={{ paddingLeft: 2, fontSize: "16px" }}>{lang}</Typography>
                }
            </ListItemIcon>
            <ListItemText className="textLabel" style={{ color: "#fff", paddingLeft: "15px" }}>
                <span style={{ fontSize: "14px" }}>{title}</span>
                {external ? <OpenInNewIcon className="badgeLabel" style={{ marginLeft: 8, marginBottom: -3, color: "#fff", fontSize: "14px" }} /> : null}
            </ListItemText>
            <Box sx={{ flexGrow: 1 }} />
            {badge ? <Typography className="badgeLabel" color="primary" style={{ backgroundColor: "#548235", fontSize: "12px", padding: "3px 6px 3px 6px", borderRadius: 5 }} component="div">{badge}</Typography> : null}
            {hasChildren ? <NavigateNextIcon className="badgeLabel" sx={{ fontSize: "24px", color: "#ffffff" }} /> : null}
        </MenuItem>
    );
}

//TODO:Theme
const ServiceItemLocked = ({ to, title, icon, tooltip, item }) => {
    const Icon = icons[icon];

    return (
        <MenuItem sx={{
            marginTop: "-1px",
            marginBottom: "-1px",
            boxShadow: 0,
            "&:hover": {
                backgroundColor: '#ffffff1e'
            }
        }} >
            <Link to={to} state={{ item: item }} style={{ textDecoration: "none", display: "flex" }}>
                <ListItemIcon style={{ fill: "#aaa" }} >
                    <Icon style={{ color: "#aaa", fontSize: "24px" }} />
                </ListItemIcon>
                <ListItemText className="textLabel" style={{ color: "#aaa", paddingLeft: "15px" }}>
                    <span style={{ fontSize: "14px" }}>{title}</span>
                </ListItemText>
                <Tooltip title={tooltip}>
                    <InfoIcon className="textLabel" style={{ marginLeft: 16, color: "#fff", fontSize: "24px" }} />
                </Tooltip>
            </Link>
        </MenuItem>
    );
}

//TODO:Theme
const SubmenuItem = ({ id, title, description, badge, onClick, external, selected }) => {
    return (
        <Card
            square
            onClick={() => onClick(id)}
            style={{ marginBottom: 16, padding: 8 }}
            sx={{
                cursor: "pointer",
                borderRight: (selected ? "3px solid #ffffff" : null),
                backgroundColor: (selected ? "#ffffff42" : "transparent"),
                boxShadow: 0,
                "&:hover": {
                    backgroundColor: '#ffffff1e'
                }
            }}
        >
            <div style={{ display: "flex" }} >
                <Typography color="primary" style={{ fontWeight: "bold", fontSize: "16px" }} component="div">
                    {title}
                </Typography>
                {external ? <OpenInNewIcon className="textLabel" style={{ marginLeft: 8, marginTop: 5, color: "#800000", fontSize: "16px" }} /> : null}
                <Box sx={{ flexGrow: 1 }} />
                {badge ? <Typography color="primary" style={{ backgroundColor: "#548235", fontSize: "12px", padding: "3px 6px 3px 6px", borderRadius: 5 }} component="div">{badge}</Typography> : null}
            </div>
            <Typography style={{ color: "#000", fontSize: "16px" }} component="div">
                {description}
            </Typography>
        </Card>

    );
}

//TODO:Theme
const SubmenuContainer = ({ title, Icon, items, onClick, closeButton }) => {
    return (
        <div style={{ padding: 16 }}>
            <div style={{ display: "flex" }}>
                <div style={{ fill: "#800000" }}>
                    <Icon style={{ color: "#800000", fontSize: 24 }} />
                </div>
                <Typography color="primary" style={{ marginLeft: 6, fontWeight: "bold" }} variant="h6" component="div">
                    {title}
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                {closeButton ? <IconButton onClick={() => onClick(false)} sx={{ "&:hover": { backgroundColor: '#0001e' } }}><CloseIcon style={{ color: "#000" }} /></IconButton> : null}
            </div>
            {items}
        </div>);
}

// Query parameters
const searchParams = new URLSearchParams(document.location.search);

export class GlobalMenu extends React.Component {
    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        this.state = {
            menu: "0",
            lang: "en",
            features: [],
            openVuokra: false,
            openVuokrat: false,
            openYlla: false,
            openAsiakas: false,

            isAdmin: false,
            profiili: null,
            data: null,
            organization: null,
            organizations: [],

            showBackdrop: false,
            showSubmenu: false,
            parentObj: null,
            parentId: null,
            selectedObj: null,
            selectedId: this.props.selected,
            redirect: null,
            errorShow: false,
            errorTitle: '',
            errorBody: '',
            globalid: null,
            downloadfile: null, 

            anchorElLang: null
        };

        //Query parameters
        const linkType = searchParams.get('type');
        const id = searchParams.get('id');
        const parent = searchParams.get('parent');
        const _menu = searchParams.get('menu') || "0";
        const _download = searchParams.get('download') || "0";
        const urlReportLink = (linkType == "report" && id && parent);
        const urlFolderLink = (linkType == "folder" && parent);
        const urlFileLink = (linkType == "file" && id);
        const urlInfoLink = (linkType == "info" && id);

        //Organisaatiot
        authFetch(this.props.pca, 'api/Profiilit/Read')
            .then(response => response.json())
            .then(data => {
                if (Array.isArray(data)) {
                    const orgid = (data.filter(d => d.aktiivinen)[0] || []).profiilitId;
                    this.setState({
                        organization: orgid,
                        organizations: data,
                    });

                    //Features
                    authFetch(this.props.pca, 'api/Toiminto/Read')
                        .then(response => response.json())
                        .then(data => {
                            var features = data.filter(d => d.aktivoitu).map(d => d.toiminto); //.filter((v, i, a) => a.indexOf(v) == i); //uniikit
                            this.setState({
                                features: features
                            }, () => {
                                // URlissa ohjaus helppiin
                                if (urlInfoLink) {
                                    this.handleHelp(9992, id);
                                }
                            });
                        });

                    //Profiili
                    authFetch(this.props.pca, 'api/Profiili/Read')
                        .then(response => response.json())
                        .then(data => {
                            if ((data || {}).error) {
                                this.handleError(data.error);
                            } else {
                                if (data.length > 0) {
                                    this.setState({
                                        profiili: data[0],
                                        redirect: !data[0].termsApproved ? "/terms" : null,
                                        menu: (((data || []) || {}).oletusNakyma == "V" ? "1" : "0"),
                                        lang: (((data || []) || {}).kieli || this.state.lang),
                                    });
                                }
                            } 
                        });

                    //Navigaatio
                    authFetch(this.props.pca, 'api/Raportti/Navigaatio')
                        .then(response => response.json())
                        .then(data => {
                            this.setState({
                                navstruct: data,
                                lang: this.context.userLanguage,
                                menu: _menu
                            }, () => {
                                // Urilissa ohjaus raporttiin
                                if (urlReportLink) {
                                    this.handleUrlReportLink(parent, id);
                                } else if (urlFolderLink) {
                                    this.handleDocuments(9991, parent);
                                } else if (urlFileLink) {
                                    this.handleDownload(id, _download);
                                }
                            });
                        });
                }
            })
            .catch((error) => {
                console.warn("Reloading page...");
                //logOut(this.props.pca, 1);
            });
    }

    componentDidMount() {
        this.setState({ lang: this.context.userLanguage || this.state.lang })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selected !== this.props.selected) {
            this.setState({ selectedId: this.props.selected });
        }
        if ((prevProps.itemId != this.props.itemId || prevProps.parentId != this.props.parentId) && this.props.parentId && this.props.itemId) {
            this.handleUrlReportLink(this.props.parentId, this.props.itemId);
            if (this.props.menuId > -1) {
                this.setState({ menu: this.props.menuId.toString() });
            }
        }
    }

    handleLogEvent = (e, target, action, info) => {
        if (e) e.preventDefault();

        authPost(this.props.pca, 'api/EventLog/Create', {
            body: JSON.stringify({
                target: target,
                action: action,
                info: info
            })
        })
    }

    handleBackdrop = (value) => {
        this.setState({ showBackdrop: value })
    }

    openSubmenu = (value) => {
        this.setState({ showSubmenu: value, showBackdrop: value });
    }

    handleService = (value) => {
        this.setState({
            menu: value,
            showBackdrop: true
        });

        this.handleLogEvent(null, "Service", "Click", value);
    }

    handleServiceDirectLink = (value) => {
        const parent = cloneDeep(findNestedObj(this.state.navstruct, "id", value));
        const obj = parent.children[0];
        let url = obj?.link;
        const typeid = obj?.typeId;
        parent.id = (parent.id || '');

        if (typeid === 20 || typeid === 55) { // PowerBI=20 tai Sivu=55
            this.setState({
                showBackdrop: false,
                showSubmenu: false,
                parentObj: parent,
                parentId: parent.id,
                selectedObj: obj,
                selectedId: obj.id,
                redirect: url
            });
        } else {
            this.setState({
                showBackdrop: false,
                showSubmenu: false,
                parentObj: null,
                parentId: null,
                selectedObj: null,
                selectedId: null,
                redirect: null
            });
        }

        this.handleLogEvent(null, "Reports Link", "Click", ((obj || {}).serviceId) + ';' + url); //TietopalveluAvain;Url
    }

    handleSubmenu = (value) => {
        const obj = findNestedObj(this.state.navstruct, "id", value);
        obj.id = (obj.id || '');

        if (obj) {
            this.setState({
                showBackdrop: true,
                showSubmenu: true,
                parentObj: obj,
                parentId: obj.id
            });
        }

        this.handleLogEvent(null, "Reports Section", "Click", value);
    }

    handleSubmenuLink = (value) => {
        const obj = findNestedObj(this.state.navstruct, "id", value);
        let url = obj?.link;
        const typeid = obj?.typeId;

        if (typeid === 20 || typeid === 55) { // PowerBI=20 tai Sivu=55
            this.setState({
                showBackdrop: false,
                showSubmenu: false,
                selectedObj: obj,
                selectedId: obj.id,
                redirect: url
            });
        } else {
            this.setState({
                showBackdrop: false,
                showSubmenu: false,
                selectedObj: null,
                selectedId: null,
                redirect: null
            });
        }

        this.handleLogEvent(null, "Reports Link", "Click", obj?.serviceId + ';' + obj?.id); //TietopalveluAvain;KohdeAvain
    }

    handleUrlReportLink = (parentId, itemId) => {
        const parent = cloneDeep(findNestedObj(this.state.navstruct, "id", parentId));
        const obj = findNestedObj(this.state.navstruct, "id", itemId);
        let url = obj?.link;
        const typeid = obj?.typeId;
        parent.id = (parent.id || '');

        if (typeid === 20 || typeid === 55) { // PowerBI=20 tai Sivu=55
            this.setState({
                showBackdrop: false,
                showSubmenu: false,
                parentObj: parent,
                parentId: parent.id,
                selectedObj: obj,
                selectedId: obj.id,
                redirect: url
            });
        } else {
            this.setState({
                showBackdrop: false,
                showSubmenu: false,
                parentObj: null,
                parentId: null,
                selectedObj: null,
                selectedId: null,
                redirect: null
            });
        }

        this.handleLogEvent(null, "Reports Link", "Click", obj?.serviceId + ';' + obj?.id); //TietopalveluAvain;KohdeAvain
    }

    handleDocuments = (value, folderid) => {
        this.setState({
            showBackdrop: false,
            showSubmenu: false,
            parentId: value,
            redirect: "/tiedostot" + (folderid ? "/?parent=" + folderid : "")
        });

        this.handleLogEvent(null, "ReportDocuments", "Click", value);
    }

    handleDownload = (id, download) => {
        this.setState({
            globalid: id,
            downloadfile: (download === 1 ? true : false),
        });
        this.handleLogEvent(null, "Download", "Click", id);
    }

    handleHome = (value) => {
        this.setState({
            showBackdrop: false,
            showSubmenu: false,
            selectedId: null,
            parentId: null,
            redirect: "/"
        });

        this.handleLogEvent(null, "Home", "Click", "");
    }

    handleHelp = (value, pageid) => {
        this.setState({
            showBackdrop: false,
            showSubmenu: false,
            parentId: value,
            redirect: "/help" + (pageid ? "/?path=" + pageid : "")
        });

        this.handleLogEvent(null, "Help", "Click", "");
    }

    handleProfile = (value) => {
        this.setState({
            showBackdrop: true,
            showSubmenu: true,
            parentId: value,
        });

        this.handleLogEvent(null, "Profile Section", "Click", "");
    }

    handleProfileLink = (value) => {
        this.setState({
            showBackdrop: false,
            showSubmenu: false,
            selectedId: value,
            redirect: value
        });

        this.handleLogEvent(null, "Profile Link", "Click", value);
    }

    handleLogout = () => {
        this.handleLogEvent(null, "Logout", "Click", "");
        logOut(this.props.pca);
    }

    handleOrganizationChange = (e) => {
        const id = e.target.value;
        let nav = null;

        if (id != this.state.organization) {
            authFetch(this.props.pca, 'api/Profiilit/Activate/' + id)
                .then(response => response.json())
                .then(data => {
                    if (!data.error) {

                        //Navigaatio
                        authFetch(this.props.pca, 'api/Raportti/Navigaatio')
                            .then(response => response.json())
                            .then(data => {
                                nav = data;

                                //Features
                                authFetch(this.props.pca, 'api/Toiminto/Read')
                                    .then(response => response.json())
                                    .then(data => {
                                        var features = data.filter(d => d.aktivoitu).map(d => d.toiminto); //.filter((v, i, a) => a.indexOf(v) == i); //uniikit
                                        this.setState({
                                            navstruct: nav,
                                            features: features,
                                            organization: id,
                                            showBackdrop: false,
                                            showSubmenu: false,
                                            selectedId: null,
                                            parentId: null,
                                            redirect: "/"
                                        })
                                    });
                            });
                    }
                });
        }
    }

    handleError = (error) => {
        var title = '', body = ''
        if (error == 4) {
            title = this.context.dictionary.ErrorDialog.Error4.title;
            body = this.context.dictionary.ErrorDialog.Error4.body;
        } else if (error == 5) {
            title = this.context.dictionary.ErrorDialog.Error5.title;
            body = this.context.dictionary.ErrorDialog.Error5.body;
        } else {
            title = this.context.dictionary.ErrorDialog.Error.title;
            body = this.context.dictionary.ErrorDialog.Error.body;
        }
        this.setState({
            errorShow: true,
            errorTitle: title,
            errorBody: body
        })
    }

    handleErrorClose = () => this.setState({ errorShow: false });

    handleLanguageChange = (event) => {
        const { userLanguageChange } = this.context;
        this.setState({ anchorElLang: null });
        userLanguageChange((event.currentTarget.dataset || {}).lang)
    };

    handleOpenLangMenu = (event) => {
        this.setState({ anchorElLang: event.currentTarget });
    };

    handleCloseLangMenu = () => {
        this.setState({ anchorElLang: null });
    }

    getServiceItem = (d) => {
        const { dictionary } = this.context;
        const { parentId, lang } = this.state;

        const uiLang = lang === 'fi' ? 'fi' : 'en';

        const notLocked = !d.locked && (d.children || []).length > 0 && ((d.children[0] || {}).lang[uiLang] || {}).title ? true : false;
        const isReport = d.isReport && (d.children || []).length > 0;
        const badges = (d.children || []).filter(d => (d.lang[uiLang] || {}).badge);

        return notLocked ?
            isReport ? (
                <ServiceItem
                    key={d.id}
                    id={d.id}
                    title={d.children[0].lang[uiLang].title}
                    icon={d.icon}
                    onClick={this.handleServiceDirectLink}
                    external={(d.children[0].typeId == 21)}
                    selected={d.id == parentId}
                    badge={badges.length == 1 ? badges[0].lang[uiLang]?.badge : false}
                    hasChildren={false}
                />
            ) : (
                <ServiceItem
                    key={d.id}
                    id={d.id}
                    title={d.lang[uiLang].title}
                    icon={d.icon}
                    onClick={this.handleSubmenu}
                    external={(d.typeId == 21)}
                    selected={d.id == parentId}
                    badge={badges.length}
                    hasChildren={true}
                />
            ) : (
                <ServiceItemLocked
                    key={d.id}
                    to={d.link}
                    title={d.lang[uiLang].title}
                    icon={d.icon}
                    tooltip={dictionary.GlobalMenu.ServiceNotAvailable}
                    item={d}
                />
            );
    }

    render() {
        const { userLanguage, dictionary } = this.context;
        const { errorShow, errorTitle, errorBody, features, showBackdrop, showSubmenu, parentObj, selectedObj, selectedId, menu,
            redirect, navstruct, parentId, organizations, organization, globalid, downloadfile, anchorElLang, lang } = this.state;

        const uiLang = lang === 'fi' ? 'fi' : 'en';

        const services = !navstruct?.children ? null : navstruct.children[menu].children
            .filter(d => d.locationId == 23) //TODO: 22 = top, 23 = services, 24 = sectors
            .map(d => this.getServiceItem(d));

        const submenu = () => {
            const obj = parentObj;

            if (parentId == 9993) {
                //9993 = Profiili
                const al = dictionary.GlobalMenu.Asetukset;
                var arr = [["UI_Etusivu_Luku", "/termsview", dictionary.Terms.Title, dictionary.Terms.Description]];

                const myitems = arr
                    .map((d, i) => {
                        return (
                            <SubmenuItem key={i} id={d[1]} title={d[2]} description={d[3]} badge={null} onClick={this.handleProfileLink} selected={selectedId == d[1]} />
                        );
                    });

                const adminitems = [
                    ["UI_Asiakas_Kirjoitus", "/asiakas", al.Asiakas, al.AsiakasKuvaus],
                    ["UI_Profiili_Kirjoitus", "/profiilit", al.Kayttajat, al.KayttajatKuvaus],
                    ["UI_Rooli_Kirjoitus", "/roolit", al.Roolit, al.RoolitKuvaus],
                    ["UI_Roolit_Kirjoitus", "/profiiliroolit", al.Kayttajienroolit, al.KayttajienroolitKuvaus],
                    ["UI_RooliOikeudet_Kirjoitus", "/roolioikeudet", al.Kayttooikeudet, al.KayttooikeudetKuvaus],
                    ["UI_Toiminto_Kirjoitus", "/toiminnot", al.Toiminnot, al.ToiminnotKuvaus],
                    ["UI_Raportti_Kirjoitus", "/raportit", al.Raportti, al.RaporttiKuvaus],
                    ["UI_Karuselli_Kirjoitus", "/karuselli", al.Karuselli, al.KaruselliKuvaus],
                    ["UI_Koodi_Kirjoitus", "/koodi", al.Koodi, al.KoodiKuvaus],
                    ["UI_Koodiryhma_Kirjoitus", "/koodiryhma", al.Koodiryhma, al.KoodiryhmaKuvaus],
                ].map((d, i) => {
                    return (
                        <FeatureTrim key={i * 100} features={features} feature={d[0]}>
                            <SubmenuItem key={i} id={d[1]} title={d[2]} description={d[3]} badge={null} onClick={this.handleProfileLink} selected={selectedId == d[1]} />
                        </FeatureTrim>
                    );
                });

                return (
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        sx={{ height: "100vh", overflowY: "auto" }}
                    >
                        <FeatureTrim features={features} feature={"UI_Asetukset_Kirjoitus"}>
                            <Grid item sx={{ width: 300 }}>
                                <SubmenuContainer
                                    key={1}
                                    title={al.Asetukset}
                                    Icon={SettingsIcon}
                                    items={adminitems}
                                    onClick={this.openSubmenu}

                                />
                            </Grid>
                        </FeatureTrim>
                        <Grid item sx={{ width: 300 }}>
                            <SubmenuContainer
                                key={1}
                                title={dictionary.Profile.Title}
                                Icon={AccountCircleIcon}
                                items={myitems}
                                onClick={this.openSubmenu}
                                closeButton={true}
                            />
                            {(organizations || []).length > 1 ? (
                                <OrganizationSelect
                                    title={dictionary.GlobalMenu.Asetukset.ValitseAsiakas}
                                    items={organizations}
                                    value={organization}
                                    onChange={this.handleOrganizationChange}
                                />
                            ) : null}
                        </Grid>

                    </Grid>
                );

            } else if (obj) {
                // Palvelut/raportit
                const submenuitems = (obj.children || []).map(d => {
                    return (
                        <SubmenuItem key={d.id} id={d.id} title={((d.lang[uiLang] || {}).title || "")} description={((d.lang[uiLang] || {}).description || "")} badge={(d.lang[uiLang] || {}).badge || null} onClick={this.handleSubmenuLink} external={(d.typeId == 21)} selected={selectedId == d.id} />
                    );
                });

                return (
                    <div style={{ width: 400, height: "100vh", overflowY: "auto" }}>
                        <SubmenuContainer
                            title={obj.lang ? obj.lang[uiLang].title : ""}
                            Icon={obj.icon ? icons[obj.icon] : HelpIcon}
                            items={submenuitems}
                            onClick={this.openSubmenu}
                            closeButton={true}
                        />
                    </div>
                );

            } else {
                // Ei mitään

                return null;
            }

        }

        const navigateToUrl = (url) => {
            if (url) {
                if (url.startsWith("http")) {
                    window.location.href = url;
                } else {
                    return (<Navigate to={url} state={{ parent: parentObj, item: selectedObj }} />);
                }
            } else {
                return null;
            }
        }

        return (
            <div style={{ zIndex: 1, height: "100%", minHeight: "100%" }}>
                {navigateToUrl(redirect)}
                <Backdrop open={showBackdrop} style={{ zIndex: -1 }}>
                </Backdrop>
                <Box
                    sx={{
                        display: (showSubmenu ? "block" : "none"),
                        zIndex: -1, position: "absolute",
                        left: 58,
                        top: 0,
                        height: "100vh",
                        minHeight: "100%",
                        backgroundColor: "#ffffff",
                        boxShadow: 8,
                        border: "2px solid #800000"
                    }}>
                    {submenu()}
                </Box>
                <Box
                    style={{ height: "100vh", minHeight: 600, width: 58, background: "#800000", transition: 'width 0.15s, visibility 2s' }}
                    sx={{
                        overflowX: "hidden",
                        "& .serviceButtons": {
                            width: "75%",
                            "& .radioIcon": {
                                visibility: "collapse"
                            },
                            "& .groupIcon": {
                                visibility: "visible",
                                paddingTop: "6px"
                            },
                        },
                        "& .textLabel": {
                            visibility: "hidden",
                            maxWidth: 0,
                            minHeight: "24px",
                            maxHeight: "24px"
                        },
                        "& .badgeLabel": {
                            visibility: "hidden",
                        },
                        "& .mainTextLabel": {
                            visibility: "collapse",
                            color: "#fff"
                        },
                        '&:hover': {
                            width: "280px !important",
                            "& .serviceButtons": {
                                width: "94%",
                                "& .radioIcon": {
                                    visibility: "visible",
                                    color: "#fff"
                                },
                                "& .groupIcon": {
                                    visibility: "hidden",
                                    maxWidth: 0
                                },
                            },
                            "& .textLabel": {
                                visibility: "visible",
                                color: "#fff",
                                maxWidth: "100%",
                                minHeight: "24px",
                                maxHeight: "24px"
                            },
                            "& .badgeLabel": {
                                visibility: "visible",
                            },
                            "& .mainTextLabel": {
                                visibility: "visible",
                                color: "#fff",
                            },
                            "& ul": {
                                width: "280px !important"
                            },
                        }
                    }}
                >
                    {/* Home */}
                    <MenuList
                        style={{ background: "#800000" }}
                    >
                        <MenuItem
                            onClick={() => this.handleHome(null)}
                        >
                            <ListItemIcon style={{ fill: "#fff" }}>
                                <IconKarpon style={{ color: "#fff", fontSize: "30px" }} /> :
                            </ListItemIcon>
                            <ListItemText className="textLabel" style={{ color: "#fff", paddingLeft: "15px", paddingTop: "6px" }}>
                                <span style={{ fontSize: "14px" }}>{dictionary.GlobalMenu.Home}</span>
                            </ListItemText>
                        </MenuItem>
                        <hr style={{ border: "1px solid #ffffffb2", background: "#ffffffb2", borderBottom: 0, marginBottom: "-8px", marginTop: "12px" }} />
                    </MenuList>

                    {/* Tietopalvelut */}
                    {(services || []).length > 0 ? (
                        <MenuList>
                            {services}
                        </MenuList>
                    ) : null}

                    {/* Perustoiminnot */}
                    <MenuList style={{ position: "fixed", bottom: 0, width: 58, background: "#800000", paddingBottom: "0px", transition: 'width 0.15s, visibility 2s' }}>
                        <hr style={{ border: "1px solid #ffffffb2", background: "#ffffffb2", borderBottom: 0, marginTop: "-10px", marginBottom: "0px" }} />
                        <FeatureTrim key={99910} features={features} feature={"UI_Tiedosto_Luku"}>
                            <ServiceItem key={9991} id={9991} title={dictionary.GlobalMenu.Reportdocuments} icon="FeedIcon" onClick={this.handleDocuments} selected={parentId == 9991} />
                        </FeatureTrim>
                        <FeatureTrim key={99920} features={features} feature={"UI_Etusivu_Luku"}>
                            <ServiceItem key={9992} id={9992} title={dictionary.GlobalMenu.Help} icon="HelpIcon" onClick={this.handleHelp} selected={parentId == 9992} />
                        </FeatureTrim>
                        <ServiceItem key={9993} id={9993} title={dictionary.GlobalMenu.Profile} icon="AccountCircleIcon" onClick={this.handleProfile} selected={parentId == 9993} hasChildren={true} />
                        <ServiceItem key={9994} id={9994} title={dictionary.GlobalMenu.Logout} icon="LogoutIcon" onClick={this.handleLogout} />

                        <MenuItem
                            sx={{
                                marginTop: "-1px",
                                marginBottom: "-1px",
                                backgroundColor: "transparent",
                                boxShadow: 0,
                                "&:hover": {
                                    backgroundColor: '#ffffff1e'
                                }
                            }}
                            onClick={this.handleOpenLangMenu}
                        >
                            <ListItemIcon style={{ fill: "#fff" }}>
                                <Typography color="secondary" style={{ paddingLeft: 2, fontSize: "16px" }}>{dictionary.GlobalMenu.LanguageShort}</Typography>
                            </ListItemIcon>
                            <ListItemText className="textLabel" style={{ color: "#fff", paddingLeft: "15px" }}>
                                {dictionary.displayName}
                            </ListItemText>
                            <Box sx={{ flexGrow: 1 }} />
                        </MenuItem>

                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElLang}
                            style={{
                                transform: "translateX(5px) translateY(-60px)",
                            }}
                            keepMounted
                            open={Boolean(anchorElLang)}
                            onClose={this.handleCloseLangMenu}

                        >
                            {
                                Object.entries(languageOptions).map(([id, name]) => (
                                    <MenuItem key={id} data-lang={id} selected={userLanguage === id} onClick={(e) => { this.handleLanguageChange(e) }}>
                                        <MenuTypo>{name}</MenuTypo>
                                    </MenuItem>
                                ))
                            }
                        </Menu>

                    </MenuList>
                </Box>
                <ErrorDialog open={errorShow} title={errorTitle} body={errorBody} onClose={this.handleErrorClose} />
                {globalid ? (<TiedostoLataus id={globalid} download={downloadfile} pca={this.props.pca} />) : null}
            </div>
        );
    }
}
